/**
 * Created by naomiokubo on 3/22/16.
 */
(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name app.directive:audienceController
     * @reportingController controller
     * # audienceController
     * audience builder controller
     */

    angular.module('app')
        .controller('reachReportController', ['$scope', '$rootScope', '$routeParams', '$location', 'hub', 'statcontext', 'campaignService', function ($scope, $rootScope, $routeParams, $location, hub, statcontext, campaignService) {
            var log = hub.getLog('reachReportController');
            var vm = this;
            vm.log = log;
            vm.warn = hub.getLog('reachReportController', 'warn');
            hub.page.setSidebarMode('CampaignsOnly');
            var init;
            vm.sort = { topSegments: { column: 'index', sortDesc: false, keyword: '' } };
            hub.page.setTitle('Reach Report');
            vm.topSegments = null;
            vm.totals = null;
            vm.segmentsPieChartOptions = null;

            vm.filterText = '<LOADING...>';
            var campaignId = null;

            function setReportFilterName() {
                if (!campaignId)
                    vm.filterText = 'All Campaigns';
                else {
                    if ($rootScope.rootVM.campaigns) {
                        var c = _.find($rootScope.rootVM.campaigns, { campaignId: parseInt(campaignId) });
                        vm.filterText = c.name;
                    }
                }
            }

            $scope.$on('campaignsLoaded', function (list) {
                setReportFilterName();
            });

            vm.handleRoute = function () {

                campaignId = $routeParams.campaignId;
                campaignService.getAccounts(2);

                setReportFilterName();


                vm.loadReport(campaignId ? 'campaign' : 'account', campaignId);


                $scope.$on('campaignSelected', function () {
                    var selectedCampaign = campaignService.getSelectedCampaign();
                    if (selectedCampaign == 'All Campaigns') {
                        if ($location.$$path != '/reporting/reach')
                            $location.path('reporting/reach/');
                    }
                    else
                        $location.path('reporting/reach/' + selectedCampaign.campaignId);
                });
            }





            vm.metricsConfig = [{ name: 'Total Reach', field: 'uniqueMessaged' }, { name: 'Ave Frequency', field: 'averageFrequency' }, { name: 'Impressions', field: 'impressions' }, { name: 'Clicks', field: 'clicks' }];




            vm.loadReport = function (type, id) {
                statcontext.segmentsReport(type, id)
                    .then(function (data) {
                        if (!data.header) {
                            vm.totals = {};
                            vm.topSegments = [];
                            return;
                        }
                        vm.totals = data.header;

                        var impressions = data.header.impressions;

                        var results = data.results;
                        var otherSegmentsStat = { fullName: 'Other segments', impressions: (data.header.impressions - hub.page.getTotal(results, 'impressions')) };
                        results.push(otherSegmentsStat);

                        results.forEach(function (item, iax) {
                            item.index = iax;
                            item.shareOfTotalImpressions = item.impressions / impressions;
                        });

                        vm.topSegments = results;
                        var chartConfig = angular.copy(hub.config.chart.templates.pie);
                        chartConfig.dataProvider = results;
                        chartConfig.titleField = 'fullName';
                        chartConfig.valueField = 'impressions';
                        chartConfig.labelText = '[[no-label]]';
                        chartConfig.radius = '50%'
                        vm.segmentsPieChartOptions = chartConfig;
                    });

            };

            vm.config = hub.config;


            vm.handleRoute();

            return vm;
        }]);


})();
