(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.controller:sidebarController
 * @sidebarController controller
 * # sidebarController
 * side bar controller. shows dependent objects
 */

    angular.module('app')
        .controller('sidebarController', ['$scope', '$rootScope', '$location', 'hub', 'context', 'logger', 'campaignService', '$filter', '$timeout', function ($scope, $rootScope, $location, hub, context, logger, campaignService, $filter, $timeout) {
            var vm = $scope.vm;
            var rootVM = $rootScope.rootVM;
            vm.isReport = false;
            vm.hasCampaignsFilter = false;
            vm.filter = $filter;
            vm.page = hub.page;
            vm.categories = [];
            vm.path = function () {
                return $location.path();
            };
            vm.showAllCampaigns = true;
            $scope.$watch(vm.path, function (newVal, oldVal) {
                if (newVal === oldVal) {
                    return;
                }

                checkPath(newVal);
            });

            function isCampaignSummary(path) {
                return path.indexOf('/reporting/campaign-summary') > -1 || path.indexOf('/reporting/new-to-advertiser') > -1 || path.indexOf('/reporting/sales-channel') > -1;
            }

            function isSellSideReport(path) {
                return path.indexOf('/reporting/sell') > -1;
            }

            //validate path and set isCustomReport flag when in custom report
            function checkPath(newVal) {
                if (isSellSideReport(newVal) || newVal.indexOf('/reporting/custom') >= 0 || newVal.indexOf('/reporting/site') >= 0 || (newVal.indexOf('/reporting/sales') >= 0 && newVal.indexOf('/reporting/sales-channel') == -1))
                    vm.isReport = true;
                else
                    vm.isReport = false;

                vm.hasCampaignsFilter = newVal.indexOf('reporting/campaign-summary') !== -1;

                vm.showAllCampaigns = !isCampaignSummary(newVal);
                if (newVal.endsWith('/activation/campaign'))
                    campaignService.setSelectedCampaign("All Campaigns");
            }

            vm.keyword = '';
            vm.filterCampaigns = function (campaigns) {
                var filtered = [];
                var filterKey = vm.keyword.toLowerCase();
                return campaigns.reduce(function (filtered, c) {
                    if (vm.keyword == c.campaignId || c.name.toLowerCase().includes(filterKey)) {
                        filtered.push(c);
                    }
                    else {
                        if (c.adGroups) {
                            var adGroups = c.adGroups.filter(ag => vm.keyword == ag.adGroupId || ag.name.startsWith(vm.keyword));
                            if (adGroups.length > 0) {
                                var campaign = { ...c };
                                campaign.adGroups = adGroups;
                                filtered.push(campaign);
                            }
                        }
                    }
                    return filtered;
                }, []);
            }

            checkPath($location.path());

            vm.mode = function () {
                return vm.page.sidebarMode();
            };

            vm.pageNumber = 1;
            vm.pageSize = 25;
            /* Campaigns ************************************** */
            vm.campaigns = [];
            vm.filteredCampaigns = [];
            vm.pagedCampaigns = [];

            vm.sort = {};
            vm.sort.campaigns = { column: 'impressions', sortDesc: true, keyword: '' };
            if (vm.path().indexOf('analytics') === -1
                && vm.path().indexOf('settings') === -1
                && vm.path().indexOf('users') === -1
                && vm.path().indexOf('users') === -1
                && vm.path().indexOf('reporting') === -1 // for publisher user we don't need to load campaigns since they don't have access to list campaigns => 401 error witll cause app/auth redirect
                && !isSellSideReport(vm.path())
                || isCampaignSummary(vm.path())) {
                campaignService.getCampaignsList().then(list => {
                    vm.campaigns = list;
                    vm.filteredCampaigns = vm.getFilteredCampaigns();
                    vm.pagedCampaigns = vm.getPagedCampaigns();
                    //remove dependent advertiser campaigns
                    if (vm.advertisersWithCampaigns)
                        vm.advertisersWithCampaigns.forEach(function (item) {
                            item.campaigns = [];
                            vm.selectAdvertiser(item);
                        });
                });
            }

            vm.hasCampaigns = function (advertiser) {
                return advertiser.campaigns && advertiser.campaigns.length > 0;
            }

            vm.getFilteredCampaigns = function () {
                var filtered = _.sortBy(vm.campaigns, 'name');
                if (vm.keyword) {
                    filtered = vm.filterCampaigns(vm.campaigns);
                }
                return filtered;
            };


            vm.getPagedCampaigns = function () {
                var paged = vm.filteredCampaigns;
                if (paged && paged.length > vm.pageSize) {
                    paged = paged.slice((vm.pageNumber - 1) * vm.pageSize, vm.pageNumber * vm.pageSize - 1);
                }
                return paged;
            };

            vm.keywordChanged = function () {
                vm.pageNumber = 1;
                vm.filteredCampaigns = vm.getFilteredCampaigns();
                vm.pagedCampaigns = vm.getPagedCampaigns();
            }

            vm.keywordClean = function () {
                vm.keyword = '';
                vm.keywordChanged();
            }

            vm.pageChanged = function () {
                vm.pagedCampaigns = vm.getPagedCampaigns();
            };

            $rootScope.$on('campaignSelected', function (e, campaign) {
                if (campaign && campaign.campaignId) {
                    vm.selectedCampaign = campaign;
                    vm.selectedGroup = null;
                    if (!vm.sidebarClicked) {
                        vm.sidebarClicked = true;
                        if (vm.advertisersWithCampaigns) {
                            var advertiserElemSelector = '#advertiser' + campaign.advertiserId;
                            $timeout(function () {
                                if (!$(advertiserElemSelector).parent('li').hasClass('open'))
                                    $(advertiserElemSelector).trigger("click");
                                vm.sidebarClicked = false;
                            });
                        }
                        else {
                            var campaignElemSelector = '#campaign' + campaign.campaignId;
                            $timeout(function () {
                                if (!$(campaignElemSelector).parent('a').hasClass('active'))
                                    $(campaignElemSelector).trigger("click");
                                vm.sidebarClicked = false;
                            });
                        }
                    }
                }
            });

            $rootScope.$on('adGroupSelected', function (e, adGroup) {
                vm.selectedGroup = adGroup;
                vm.campaigns.forEach(function (c) {
                    if (c.campaignId == adGroup.campaignId) {
                        vm.selectedCampaign = c;
                        if (!vm.sidebarClicked) {
                            vm.sidebarClicked = true;
                            if (vm.advertisersWithCampaigns) {
                                var advertiserElemSelector = '#advertiser' + vm.selectedCampaign.advertiserId;
                                $timeout(function () {
                                    if (!$(advertiserElemSelector).parent('li').hasClass('open'))
                                        $(advertiserElemSelector).trigger("click");
                                    vm.sidebarClicked = false;
                                });
                            }
                            else {
                                var campaignElemSelector = '#campaign' + vm.selectedCampaign.campaignId;
                                $timeout(function () {
                                    if (!$(campaignElemSelector).parent('a').parent('li').hasClass('open'))
                                        $(campaignElemSelector).trigger("click");
                                    vm.sidebarClicked = false;
                                });
                            }
                        }
                    }
                });
            });

            vm.selectedCampaign = campaignService.getSelectedCampaign();

            vm.selectedCampaignClass = function (c) {
                var selectedCampaign = campaignService.getSelectedCampaign();
                if (c == selectedCampaign && vm.selectedGroup == null)
                    return 'active';
                else
                    return '';
            }

            vm.selectedCategoryClass = function (c) {
                if (rootVM.selectedCategory && c.id == rootVM.selectedCategory.id)
                    return 'active';
                else
                    return '';
            }

            campaignService.setSelectedCampaign('All Campaigns');

            vm.selectAdvertiser = function (advertiser) {
                advertiser.campaigns = [];
                advertiser.campaignIds.forEach(function (item) {
                    var cmp = _.find(vm.campaigns, { campaignId: item });
                    if (cmp)
                        advertiser.campaigns.push(cmp);
                });
            };

            vm.selectCategory = function (cat) {
                rootVM.selectedCategory = cat;
                $rootScope.$broadcast('categorySelected', cat);
            }

            vm.selectCampaign = function (campaign) {
                vm.sidebarClicked = true;

                if (campaign.campaignId) {
                    vm.selectedCampaign = campaign;
                    campaignService.setSelectedCampaign(campaign);
                }
                else {
                    vm.selectedCampaign = null;
                    campaignService.setSelectedCampaign("All Campaigns");
                }

                vm.selectedGroup = null;

                if ($location.path().indexOf('reporting/campaign-summary') > 0 && campaign.campaignId) {
                    $location.path('reporting/campaign-summary/C' + campaign.campaignId, true);
                    return;
                }
                if ($location.path().indexOf('reporting/new-to-advertiser') > 0 && campaign.campaignId) {
                    $location.path('reporting/new-to-advertiser/C' + campaign.campaignId, true);
                    return;
                }
                if ($location.path().indexOf('reporting/sales-channel') > 0 && campaign.campaignId) {
                    $location.path('reporting/sales-channel/C' + campaign.campaignId, true);
                    return;
                }
                $timeout(function () {
                    vm.sidebarClicked = false;
                });

            }

            vm.getLink = function (id, type) {
                if (isCampaignSummary($location.path()))
                    return null;

                if (type == 'adgroup')
                    return 'activation/campaign/adgroup/' + id + '/edit';
                else if (type == 'campaign')
                    return 'activation/campaign/' + id;
            }

            vm.selectAdGroup = function (adGroup, campaign) {
                vm.sidebarClicked = true;

                campaignService.setSelectedAdGroup(adGroup, campaign);
                vm.selectedGroup = adGroup;

                if ($location.path().indexOf('reporting/campaign-summary') > 0 && adGroup.adGroupId) {
                    $location.path('reporting/campaign-summary/A' + adGroup.adGroupId, true);
                    return;
                }
                if ($location.path().indexOf('reporting/new-to-advertiser') > 0 && adGroup.adGroupId) {
                    $location.path('reporting/new-to-advertiser/A' + adGroup.adGroupId, true);
                    return;
                }
                if ($location.path().indexOf('reporting/sales-channel') > 0 && adGroup.adGroupId) {
                    $location.path('reporting/sales-channel/A' + adGroup.adGroupId, true);
                    return;
                }
                $timeout(function () {
                    vm.sidebarClicked = false;
                });
            }
        }]);
})();
