
(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name app.directive:audienceController
     * @reportingController controller
     * # audienceController
     * audience builder controller
     */

    angular.module('app')
        .controller('performanceDailyReportController', ['$scope', '$http','hub','api', 'page', 'config', function ($scope, $http,hub,api,page, config) {
            var log = hub.getLog('performanceDailyReportController');
            var vm = this;
            vm.log = log;
            vm.page = page;
            vm.warn = hub.getLog('performanceDailyReportController', 'warn');
            vm.config = config;
            vm.datepickers = { startDate: false, endDate: false };

            vm.from = new Date();
            vm.to = new Date();

            vm.openDatepicker = function ($event, which) {
                $event.preventDefault();
                $event.stopPropagation();
                for (var prop in vm.datepickers) {
                    vm.datepickers[prop] = false;
                }
                vm.datepickers[which] = true;
            };

            vm.canSubmit = function() {

                return $scope.form_report_daily.$valid && _.find(vm.advertisers, {checked : true});

            }
            vm.pageNumber = 0;
            vm.totalCount = 0;
            vm.data = [];

            vm.exportData = function() {
                var ids = _.map(_.filter(vm.advertisers, { checked: true }), "id");

               return api.post('api/reports/performancedaily-export',
                    {
                        pageNumber: vm.pageNumber,
                        pageSize: vm.config.pageSize(),
                        getCount: false,
                        sortColumn: 'Date',
                        parameters: { ids: ids, from: vm.from, to: vm.to }
                    })
                    .then(function(result) {

                        var data = result.results;
                       if (!data || data.length === 0)
                           return [];



                        _.forEach(data,
                            function (it, idx) {

                                var item = _.find(result.header, { adGroupId: it.adGroupId });
                                if (item)
                                    _.merge(it, item);
                                var account = _.find(vm.advertisers, { id: it.accountId });

                                if (account)
                                    it.accountName = account.name;

                            });

                        var headers = {};
                        for (var name in data[0])
                            headers[name] = name;

                        data.unshift(headers);

                        return data;
                    });


            }

            vm.runReport = function(reset) {

              var ids =   _.map(_.filter(vm.advertisers, { checked: true }), "id");
                if (reset) {
                    vm.pageNumber = 0;
                    vm.totalCount = 0;
                } else
                    reset = false;

                api.post('api/reports/performancedaily', { pageNumber: vm.pageNumber, pageSize: vm.config.pageSize(), getCount: reset, sortColumn: 'Date', parameters: { ids: ids, from: vm.from, to: vm.to } })
               .then(function (result) {
                   
                   var data = result.results;

                   if (reset)
                        vm.totalCount = result.count;

                        _.forEach(data,
                            function(it, idx) {

                                var item = _.find(result.header, { adGroupId: it.adGroupId });
                                if (item)
                                    _.merge(it, item);
                                var account = _.find(vm.advertisers, { id: it.accountId });
                                if (it.date.indexOf('T') > 0) {
                                    var date = it.date.substring(0, 10).split('-')
                                    date = date[1] + '-' + date[2] + '-' + date[0]

                                    it.date = new Date(date);
                                }
                                if (account)
                                    it.accountName = account.name;

                            });

                        vm.data = data;
               });
               

            }

            vm.sort = { topSegments: { column: 'index', sortDesc: false, keyword: '' } };
            hub.page.setTitle('Performance Daily Report');
            vm.topSegments = null;
            vm.totals = null;
            vm.segmentsPieChartOptions = null;

            vm.filterText = '<LOADING...>';
            var campaignId = null;

            vm.advertisers = [];

            api.get('api/companyAccount/names?type=1')
                .then(function(result) {
                    vm.advertisers = result;
                });

        

            vm.config = hub.config;
           

            return vm;
        }]);


})();