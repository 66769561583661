(function () {
    'use strict';
    var serviceId = 'customReportService';
    angular.module('app').factory(serviceId, ['$q', '$cacheFactory', '$location', '$routeParams', 'localStorageService', 'config', 'api', 'authService', 'featureService', 'accountService', 'campaignService', customReportFactory]);

    function customReportFactory($q, $cacheFactory, $location, $routeParams, localStorageService, config, api, authService, featureService, accountService, campaignService) {
        const hourDimention = { key: 'H', name: 'Hour', format: 'text' };
        const dayDimention = { key: 'D', name: 'Day', format: 'text' };
        const weekDimention =  { key: 'W', name: 'Week', format: 'text' };
        const monthDimention = { key: 'M', name: 'Month', format: 'text' };
        const quarterDimention =  { key: 'Q', name: 'Quarter', format: 'text' };
        const yearDimention = { key: 'Y', name: 'Year', format: 'text' };

        const activeDimensions = () => {
            const result = [hourDimention, dayDimention, weekDimention,  monthDimention, quarterDimention, yearDimention];
            return result;
        }

        const hasAccessToCampaigns = authService.hasAccess('Campaigns', 'List');

        var levels = [
            { key: 'CAMP', name: 'Campaign' },
            { key: 'FLIGHT', name: 'Ad Group Flight' },
            { key: 'AG', name: 'Ad Group' },
            { key: 'ACC', name: 'Advertiser' },
            { key: 'MARK', name: 'Marketer' }];
        //, { key: 'AS', name: 'Ad Summary' }
        var dimensions = activeDimensions();

        var getEmptyReport = function() {
            var result = {
                columnOrder: { headers: [], columns: [] },
                levels: [], dimensions: [], columns: [],
                dateFilter: { filter: 'ThisMonth', startDate: null, endDate: null },
                filter: {
                    campaigns: { showAll: true, ids: [], visible: true },
                    adGroups: { showAll: true, ids: [], visible: true },
                    advertisers: { showAll: true, ids: [], visible: true },
                    publishers: { showAll: true, ids: [], visible: true },
                    programs: { showAll: true, ids: [], visible: true },
                    channels: { showAll: true, ids: [], visible: true },
                    mediaTypes: { showAll: true, ids: [], visible: true },
                    //marketers: { showAll: true, ids: [], visible: true },
                    retailChannels: { showAll: true, ids: [], visible: true },
                    stores: { showAll: true, ids: [], visible: true },
                },
                sortOrder: {sortColumn: 'DATE', sortDescending: false }
            };

            if (featureService.isActive('MARK'))
                result.filter.marketers = { showAll: true, ids: [], visible: true };

            if ($location.path().indexOf('/reporting/sell') >= 0) {
                result.filter.channels.ids = [16]
            }

            return result;
        }

        var getEmptyFilter = function(reportType) {
            var d = {
                campaignsFilter: { hidden: false },
                campaigns: {
                    hidden: true, items: [], title: 'Campaigns', section: 'campaigns', idColumn: 'campaignId'
                },
                adGroups: { hidden: true, items: [], title: 'Ad Groups', section: 'adGroups', idColumn: 'adGroupId' },
                advertisers: { hidden: true, items: [], title: 'Advertiser', section: 'advertisers', idColumn: 'id' },
                publishers: { hidden: true, items: [], title: 'Publisher', section: 'publishers', idColumn: 'id' },
               // marketers: { hidden: true, items: [], title: 'Marketers', section: 'marketers', idColumn: 'id' },
                channels: { hidden: false, items: [], title: reportType != reportTypesEnum?.sellPerformance?.type ? 'Media Types' : 'Demand Types', section: 'channels', idColumn: 'id' },
                retailChannels: { hidden: true, items: [{ id: 1, name: 'Online' }, { id: 2, name: 'In-Store'}, /*{ id: 3, name: 'MultiChannel' }*/], title: 'Retail Channels', section: 'retailChannels', idColumn: 'id' },
                stores: { hidden: true, items: [], title: 'Stores', section: 'stores', idColumn: 'id' },
                reportBuilder: { hidden: false },
                mediaTypes: { hidden: true, items: [], title: 'Media Types', section: 'mediaTypes', idColumn: 'id' },
                mode: null
            };

            if (featureService.isActive('MARK'))
                d.marketers = { hidden: true, items: [], title: 'Marketers', section: 'marketers', idColumn: 'id' };

            return d;
        };

        var filterByKeyword = function(it, keyword){
            return keyword == null || keyword == '' || it.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
        };

        var report = getEmptyReport();

        var columns = [];

        function addToCollection(allItems, selectedItems,item)
        {

            if (!selectedItems.find(function (x) { return x.key === item.key }))
                selectedItems.push(item);
            removeFromAllItems(allItems, selectedItems);

        }
        function removeFromAllItems(allItems, selectedItems) {
            selectedItems.forEach(function (x) {

                var col = allItems.find(function (y) { return y.key === x.key });
                if (col) {
                    allItems.splice(allItems.indexOf(col), 1);
                }
            });
        }
        function removeFromCollection(allItems, selectedItems, item) {

            var it = selectedItems.find(function (x) { return x.key === item.key });

            if(it)
                selectedItems.splice(selectedItems.indexOf(it), 1);

            selectedItems.forEach(function (x) {

                var col = allItems.find(function (y) { return y.key === x.key });
                if (col) {
                    allItems.splice(allItems.indexOf(col), 1);
                }
            });
        }

        var activeFeatureFilter = function (item) {
            return featureService.isActive( (item.feature ? item.feature:  item.key) );
        };

        var customReportProps = {
            levels : [
                { key: 'CAMP', name: 'Campaign' },
                { key: 'AG', name: 'Ad Group' },
                { key: 'ACC', name: 'Advertiser' },
                { key: 'FLIGHT', name: 'Ad Group Flight' },
                { key: 'CREAT', name: 'Creative' },
               // { key: 'MARK', name: 'Marketer' }
            ],
            columns : _.clone(config.tables.columns.customReports || [])
                        .filter(activeFeatureFilter),
        };
        if (authService.hasFeature('MARK')) {
            customReportProps.levels.push({ key: 'MARK', name: 'Marketer' });
        }

        var sellPerformanceReportProps = {
            levels : [
                { key: 'PUB', name: 'Publisher' },
                { key: 'MARK', name: 'Marketer' },
                { key: 'PLC', name: 'Placement' },
                { key: 'UNIT', name: 'Ad Unit' },
                { key: 'ChannelID', name: 'Demand Type' }],
            columns : _.clone(config.tables.columns.sellReports || []),
            dimensions: activeDimensions().filter(x => x == weekDimention || x == monthDimention || x == dayDimention || x == yearDimention || x == quarterDimention),
            defaultFilters: {
                channels: [16]
            }
        };
        var sitePerformanceReportProps = {
            levels : [
                { key: 'ADV', name: 'Advertiser' },
                { key: 'CAMP', name: 'Campaign' },
                { key: 'AG', name: 'Ad Group' },
                { key: 'SITE', name: 'Site/Domain', required: true }],
            columns : _.clone(config.tables.columns.siteReports || [])
                    .filter(activeFeatureFilter),
            dimensions: activeDimensions().filter(x => x == weekDimention || x == monthDimention || x == dayDimention || x == yearDimention)
        };

        var standardFilter =  {
            campaigns: { on: true },
            adGroups: { on: true },
            advertisers: { on: true },
            programs: { on: true },
            channels: { on: true },
            marketers: {  on: true },
        };

        var sellSideFilter = {
            marketers: {on: true},
            publishers: {on: true},
            channels: { on: true, ids: [16, 19, 20] },
            units: {on: true},
            placements: {on: true},
            mediaTypes: {on:true}
        }

        var reportTypesEnum = {
            custom: {
                type: 1,
                name: 'Campaign Performance',
                url: 'reporting/custom/',
                reportProps: customReportProps,
                hasLevels: true,
                filterConfig: standardFilter,
                sortOrder: { sortColumn: 'date', sortDescending: false }
            },
             sitePerformance: {
               type: 7,
               name: 'Site Performance',
               url: 'reporting/site/',
               reportProps: sitePerformanceReportProps,
               hasLevels: true,
               filterConfig: standardFilter,
               sortOrder: { sortColumn: 'date', sortDescending: false }
            },
            sellPerformance: {
                type: 8,
                name: 'Sell Side Performance',
                url: 'reporting/sell/',
                reportProps: sellPerformanceReportProps,
                hasLevels: true,
                filterConfig: sellSideFilter,
                sortOrder: { sortColumn: 'date', sortDescending: false }
             },
            campaignSummary: {
                type: 3,
                name: 'Campaign Summary',
                url: 'reporting/campaign-summary/'
            },
        }

        var campaign_list = null;
        var adgroup_list = null;
        var advertiser_list = null;
        var publisher_list = null;
        var marketer_list = null;
        var channel_list = null;

        if (hasAccessToCampaigns) {
            campaignService.getCampaignsList().then(function (result) {
                campaign_list = result;
              });
        }

        campaignService.getAccounts(1).then(function (result) {
          advertiser_list = result;
        });

        campaignService.getAccounts(5).then(function (result) {
            publisher_list = result;
          });

        campaignService.getAccounts(2).then(function (result) {
          marketer_list = result;
        });

        var service = {
            get_marketers: function(){
              return marketer_list;
            },
            get_publishers: function(){
              return publisher_list;
            },
            get_advertisers: function(){
                return advertiser_list;
              },
            get_campaigns: function(){
              return campaign_list;
            },
            get_adgroups: function(){
              if(adgroup_list){
                return adgroup_list;
              }
              adgroup_list = [];
              campaign_list.forEach(function(campaign){
                campaign.adGroups.forEach(function(adgroup){
                  adgroup_list.push(adgroup);
                });
              });
              return adgroup_list;
            },
            get_channels: function(){
              return campaignService.getChannels();
            },
            get_mediaTypes: function() {
                return campaignService.getMediaTypes();
            },
            clean_filter: function(){
              let filtered_campaigns = null;

              if(this.report.filter.marketers && this.report.filter.marketers.ids.length > 0){
                filtered_campaigns = campaign_list.filter(item => {
                  return this.report.filter.marketers.ids.includes(item.marketerId);
                });
                this.report.filter.marketers.showAll = false;
              }else if(this.report.filter.marketers){
                this.report.filter.marketers.showAll = true;
              }

              if(filtered_campaigns && this.report.filter.advertisers.ids.length > 0){
                let found_ids = [];
                for(let advertisers_ids_index = 0; advertisers_ids_index < this.report.filter.advertisers.ids.length; advertisers_ids_index++){
                  let found = false;
                  for(let filtered_campaigns_index = 0; filtered_campaigns_index < filtered_campaigns.length && found === false; filtered_campaigns_index++){
                    if(this.report.filter.advertisers.ids[advertisers_ids_index] == filtered_campaigns[filtered_campaigns_index].advertiserId){
                      found = true;
                      found_ids.push(this.report.filter.advertisers.ids[advertisers_ids_index]);
                    }
                  }
                }
                this.report.filter.advertisers.ids = found_ids;
              }
              if(this.report.filter.advertisers.ids.length > 0){
                let search_list = filtered_campaigns ? filtered_campaigns : campaign_list;
                filtered_campaigns = search_list.filter(item => {
                  return this.report.filter.advertisers.ids.includes(item.advertiserId);
                });
                this.report.filter.advertisers.showAll = false;
              }else{
                this.report.filter.advertisers.showAll = true;
              }

              if(filtered_campaigns && this.report.filter.campaigns.ids.length > 0){
                let found_ids = [];
                for(let campaigns_ids_index = 0; campaigns_ids_index < this.report.filter.campaigns.ids.length; campaigns_ids_index++){
                  let found = false;
                  for(let filtered_campaigns_index = 0; filtered_campaigns_index < filtered_campaigns.length && found === false; filtered_campaigns_index++){
                    if(this.report.filter.campaigns.ids[campaigns_ids_index] == filtered_campaigns[filtered_campaigns_index].campaignId){
                      found = true;
                      found_ids.push(this.report.filter.campaigns.ids[campaigns_ids_index]);
                    }
                  }
                }
                this.report.filter.campaigns.ids = found_ids;
              }
              if(this.report.filter.campaigns.ids.length > 0){
                let search_list = filtered_campaigns ? filtered_campaigns : campaign_list;
                filtered_campaigns = search_list && search_list.filter(item => {
                  return this.report.filter.campaigns.ids.includes(item.campaignId);
                });
                this.report.filter.campaigns.showAll = false;
              }else{
                this.report.filter.campaigns.showAll = true;
              }

              if(filtered_campaigns && this.report.filter.adGroups.ids.length > 0){
                let found_ids = [];
                for(let adgroups_ids_index = 0; adgroups_ids_index < this.report.filter.adGroups.ids.length; adgroups_ids_index++){
                  let found = false;
                  for(let filtered_campaigns_index = 0; filtered_campaigns_index < filtered_campaigns.length && found === false; filtered_campaigns_index++){
                    for(let adgroup_index = 0; adgroup_index < filtered_campaigns[filtered_campaigns_index].adGroups.length && found === false; adgroup_index++){
                      if(this.report.filter.adGroups.ids[adgroups_ids_index] == filtered_campaigns[filtered_campaigns_index].adGroups[adgroup_index].adGroupId){
                        found = true;
                        found_ids.push(this.report.filter.adGroups.ids[adgroups_ids_index]);
                      }
                    }
                  }
                }
                this.report.filter.adGroups.ids = found_ids;
              }

              if(this.report.filter.adGroups.ids.length > 0){
                let search_list = filtered_campaigns ? filtered_campaigns : campaign_list;
                filtered_campaigns = search_list.filter(item => {
                  for(let ag_index = 0; ag_index < item.adGroups.length; ag_index++){
                    if(this.report.filter.adGroups.ids.includes(item.adGroups[ag_index].adGroupId)){
                      return true;
                    }
                  }
                });
                this.report.filter.adGroups.showAll = false;
              }else{
                this.report.filter.adGroups.showAll = true;
              }

              if(this.report.filter.channels.ids.length < 1){
                this.report.filter.channels.showAll = true;
              }else{
                this.report.filter.channels.showAll = false;
              }
            },
            the_filtered_list: function(filter, mode){
              let filtering_list = null;
              let filter_names = [];
              const filter_levels = ["marketers", "advertisers", "campaigns", "adGroups", "channels", "mediaTypes"];
              const ADVERTISERS_LEVEL = filter_levels.indexOf("advertisers");
              const CAMPAIGNS_LEVEL = filter_levels.indexOf("campaigns");
              const ADGROUPS_LEVEL = filter_levels.indexOf("adGroups");
              const CHANNELS_LEVEL = filter_levels.indexOf("channels");
              const MEDIA_TYPES_LEVEL = filter_levels.indexOf("mediaTypes");
              const filter_level = filter_levels.indexOf(mode);

              if(filter.marketers && filter.marketers.ids.length > 0){
                filter_names.push('marketers');
                filtering_list = campaign_list.filter(item => {
                  return filter.marketers.ids.includes(item.marketerId);
                });
              }

              if(filter_level > ADVERTISERS_LEVEL && filter.advertisers && filter.advertisers.ids.length > 0){
                filter_names.push('advertisers');
                let search_list = filtering_list ? filtering_list : campaign_list;
                filtering_list = search_list.filter(item => {
                  return filter.advertisers.ids.includes(item.advertiserId);
                });
              }

              if(filter_level > CAMPAIGNS_LEVEL && filter.campaigns && filter.campaigns.ids.length > 0){
                filter_names.push('campaigns');
                let search_list = filtering_list ? filtering_list : campaign_list;
                filtering_list = search_list.filter(item => {
                  return filter.campaigns.ids.includes(item.campaignId);
                });
              }

              if(filter_level > ADGROUPS_LEVEL && filter.adGroups && filter.adGroups.ids.length > 0){
                filter_names.push('adGroups');
                let search_list = filtering_list ? filtering_list : campaign_list;
                filtering_list = search_list.filter(item => {
                  for(let i = 0; i < item.adGroups.length; i++){
                    if(filter.adGroups.ids.includes(item.adGroups[i].adGroupId)){
                      return true;
                    }
                  }
                });
              }

              if(filter_level > CHANNELS_LEVEL && filter.channels && filter.channels.ids.length > 0){
                filter_names.push('channels');
                let search_list = filtering_list ? filtering_list : campaign_list;
                filtering_list = search_list.filter(item => {
                  return filter.channels.ids.includes(item.channelId);
                });
              }

              if (filter_level > MEDIA_TYPES_LEVEL && filter.mediaTypes && filter.mediaTypes.ids.length > 0) {
                filter_names.push('mediaTypes');
                let search_list = filtering_list ? filtering_list : campaign_list;
                filtering_list = search_list.filter(item => {
                  return filter.mediaTypes.ids.includes(item.mediaTypeId);
                });
              }

              let filtered_list = {
                items: filtering_list ? filtering_list : campaign_list,
                show_all: false,
                marketer_ids: [],
                advertiser_ids: [],
                channel_ids: [],
              };

              filtered_list.marketer_ids = [];
              if(mode.match(/marketers/i)){
                filtered_list.items.forEach(item => {
                  if(item.marketerId && !filtered_list.marketer_ids.includes(item.marketerId)){
                    filtered_list.marketer_ids.push(item.marketerId);
                  }
                });
              }

              filtered_list.advertiser_ids = [];
              if(mode.match(/advertisers/i)){
                filtered_list.items.forEach(item => {
                  if(item.advertiserId && !filtered_list.advertiser_ids.includes(item.advertiserId)){
                    filtered_list.advertiser_ids.push(item.advertiserId);
                  }
                });
              }

              filtered_list.channel_ids = [];
              if(mode.match(/channels/i)){
                filtered_list.items?.forEach(item => {
                  if(item.channelId && !filtered_list.channel_ids.includes(item.channelId)){
                    filtered_list.channel_ids.push(item.channelId);
                  }
                });
              }

              if(filter_names.length == 0 || (filter_names.length == 1 && filter_names.includes(mode))){
                filtered_list.show_all = true;
                filtered_list.items = campaign_list;
              }

              return filtered_list;
            },
            isValid: false,
            isLoading: false,
            reportTypesEnum: reportTypesEnum,
            info: { id: 0, name: '', type: 1 },
            reportTypes: [
                reportTypesEnum.custom,
                reportTypesEnum.sitePerformance,
                reportTypesEnum.campaignSummary,
                reportTypesEnum.sellPerformance,
            ],

            report: _.clone(report),
            dimensions: _.clone(dimensions),
            levels: _.clone(levels),
            columns: _.clone(columns),
            reportType: null,
            allowedDimensions: ['H', 'D', 'W', 'M', 'Q', 'Y'],
            setAllowedDimensions: function (filter)
            {
                if (!filter)
                    filter = this.report.dateFilter.filter;
                this.allowedDimensions = ['H', 'D', 'W', 'M', 'Q', 'Y'];
            },
            load: function(id) {
                var deferred = $q.defer();

                var that = this;
                that.isLoading = true;
                    api.get('api/reports/customreport/' + id )
                   .then(function (rep) {
                       that.resetReport(true);

                       var reportColumns = [];
                       //remove hidden features in saved report items
                       var columnOrder = rep.parameters.columnOrder || rep.parameters;

                       columnOrder.columns.forEach(function (item) {
                           var column = columns.find(function (c) { return c.key == item.key });
                           if (column != null) {

                               item.name = column.name;
                               reportColumns.push(item);
                           }
                       });

                       that.hasCampaign = columnOrder.headers.find(function (h) { return h.key == 'CAMP' });
                       that.hasAdGroup = columnOrder.headers.find(function (h) { return h.key == 'AG' });
                       that.hasCreative = columnOrder.headers.find(function (h) { return h.key == 'CREAT' });
                       that.hasFlight =  columnOrder.headers.find(function (h) { return h.key == 'FLIGHT' });
                       that.hasMarketer = columnOrder.headers.find(function (h) { return h.key == 'MARK' });

                       rep.parameters.columnOrder = rep.parameters.columnOrder || {};
                       rep.parameters.columnOrder.headers = rep.parameters.columnOrder.headers || [];

                       rep.parameters.columnOrder.columns = reportColumns;
                       reportColumns = [];

                       rep.parameters.columns.forEach(function (item) {
                           var column = columns.find(function (c) { return c.key == item.key });
                           if (column != null) {

                               item.name = column.name;
                               reportColumns.push(item);
                           }
                       });
                       rep.parameters.columns = reportColumns;

                       that.report = rep.parameters;
                       that.report.isMTA = rep.reportMta;


                       //add format description to custom report columns
                       rep.parameters.columns.forEach(function (item) {
                           var c = that.columns.find(function (it) { return it.key == item.key; });
                           if (c && c.format)
                               item.format = c.format;
                       });


                       that.info = { id: rep.id, name: rep.name, type: rep.reportType };
                       that.reportType = that.getReportType();

                      if (!that.report.dimensions)
                          that.report.dimensions = [];
                            if (!that.report.columnOrder)
                                that.report.columnOrder = { headers: [], columns: [] };
                       if (!that.report.filter.adGroups)
                           that.report.filter.adGroups = {showAll: true, ids: []};
                       if (!that.report.filter.campaigns)
                           that.report.filter.campaigns = {showAll: true, ids: []};

                       removeFromAllItems(that.levels, that.report.levels);
                       removeFromAllItems(that.columns, that.report.columns);

                            if (!that.report.dateFilter.filter)
                           that.report.dateFilter.filter = 'ExactRange';

                            that.setAllowedDimensions(that.report.dateFilter.filter);
                       removeFromAllItems(that.dimensions, that.report.dimensions);
                            that.report.sortOrder = that.report.sortOrder || that.getReportType().sortOrder;

                            if (that.report.columnOrder.headers.length == 0) {
                                that.report.levels.forEach(function (it) {
                                    that.report.columnOrder.headers.push({ name: it.name, key: it.key, type: 'level', format: it.format });

                                });
                                that.report.dimensions.forEach(function (it) {
                                    that.report.columnOrder.headers.push({ name: it.name, key: it.key, type: 'dimension', format: it.format });

                                });
                            }
                            if (that.report.columnOrder.columns.length == 0) {
                                that.report.columns.forEach(function (it) {
                                    that.report.columnOrder.columns.push({ name: it.name, key: it.key, type: 'column', format: it.format });

                                });
                            }
                            that.isLoading = false;
                            that.validate();
                            deferred.resolve(that.report);


                   }, function (error) {
                       deferred.reject(error);
                   });

                    return deferred.promise;
            },
            getReportUrl: function(typeId, useHashed) {
                var types = this.reportTypes;
                var reportType = types.find(function(t) {
                    return t.type == typeId;
                });
                var result = !reportType ? '' : reportType.url;
                return useHashed ? result : result.replace('#', '');
            },
            isCampaignAdgroupType: function(typeId) {
                return [
                    //reportTypesEnum.salesChannel.type,
                    //reportTypesEnum.newToAdvertiser.type,
                    //reportTypesEnum.campaignSummary.type
                ].indexOf(typeId) > -1;
            },
            generateName: function () {
                var deferred = $q.defer();

                var that = this;
                that.isLoading = true;
                api.get('api/reports/generatename/')
                    .then(function (d) {
                        that.isLoading = false;
                        deferred.resolve(d);
                    }, function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            getReportByName: function (name) {
                var deferred = $q.defer();

                var that = this;
                that.isLoading = true;
                api.post('api/reports/getreportbyname', { name: name })
                    .then(function (d) {
                        that.isLoading = false;
                        deferred.resolve(d);
                    }, function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            makeFirstSort: function() {
                if(this.report.columnOrder.columns.length >= 1) {
                    const col = this.report.columnOrder.columns[0];
                    this.report.sortOrder = {sortColumn: col.key, sortDescending: true };
                }
            },
            hasAdvertiser: false,
            hasCampaign: false,
            hasAdGroup: false,
            hasSegment: false,
            hasCreative: false,
            hasFlight: false,
            hasHourDim: false,
            FLIGHT_COLUMNS: ['FLIGHTCODE'],
            AG_COLUMNS: ['AGID', 'AGTRC', 'AGSD', 'AGED', 'AGBCT', 'AGBC', 'AGIG', 'AGMMB', 'AGMMBC', 'AGRMMP', 'AGRMB', 'AGRCSD', "AGCPMR", "CHAN", "B_CHAN"],
            CREAT_COLUMNS: ['CRID', 'CRSZ', 'CRTRC','CAMTRC','AGTRC'],
            ADGROUP_CAMPAIGN_COLUMNS: ['CAMID', 'COM', 'CAMTRC'],
            ACCOUNT_COLUMNS: ['MARID', 'ADVID'],
            FIN_COLUMNS: [ "PFEE", "BIFEE", "DCST", "AGRM", "RMP", "MCST"],
            NON_ADVERTISER_COLUMNS: ['FAS', 'FASIS', 'INS', 'TIS', 'TAS', 'TROAS', 'IROAS'],
            canAddLevel: function (l) {
                if (l && l.disabled) {
                    return false;
                }

                if (l.key === 'FLIGHT' && this.hasCreative) {
                    return false;
                }
                else if (l.key === 'CREAT') {
                    if (this.hasFlight) {
                        return false;
                    }
                    if (this.report.dimensions.find(function (d) { return d.key === 'H' })) {
                        return false;
                    }
                }
                return true;
            },
            canAddDimension: function (dim) {
                if (dim.key === 'H' && this.hasCreative) {
                    return false;
                }
                return this.allowedDimensions.indexOf(dim.key) >= 0;
            },
            getLevelKeys: function (all = false) {
                const keys = [];

                // Order of the if statements is important for field inheritance.
                // inheritance is done by explicitly setting all to true

                if (all) {
                    keys.push(...this.CREAT_COLUMNS);
                    keys.push(...this.FLIGHT_COLUMNS);
                } else if (this.hasCreative) {
                    keys.push(...this.CREAT_COLUMNS);
                    all = true;
                } else if (this.hasFlight) {
                    keys.push(...this.FLIGHT_COLUMNS);
                    all = true;
                }

                if (all || this.hasAdGroup) {
                    keys.push(...this.AG_COLUMNS);
                    all = true;
                }

                if (all || this.hasCampaign) {
                    keys.push(...this.ADGROUP_CAMPAIGN_COLUMNS);
                    all = true;
                }

                if (all || this.hasAdvertiser) {
                    keys.push(...this.ACCOUNT_COLUMNS);
                    all = true;
                }

                if (all || this.hasMarketer) {
                    keys.push('MARID');
                    all = true;
                }

                return keys;
            },
            canAddColumn: function (type, allowInTypes, column)
            {
                if (column && column.disabled) {
                    return false;
                }
                //show section allowInTypes sends array of report types that can be allowed
                if (allowInTypes && allowInTypes.indexOf(this.info.type) == -1)
                    return false;
                if(column && !(this.hasAdGroup || this.hasCampaign || this.hasSegment || this.hasCreative) &&
                    this.NON_ADVERTISER_COLUMNS.find(function(key){ return key == column.key })){
                    return false;
                }

                //cant add column with type='r' if report has Advertiser level
                if (type === 'r' || type === 'new_r') {
                    if (this.report.levels.length === 0 && !this.isSalesPerformance())
                        return false;
                    // column level
                    if(column) {
                        if (!this.isSalesPerformance() && (this.hasAdvertiser || this.hasMarketer || this.hasAdGroup || this.hasCampaign || this.hasSegment || this.hasCreative || this.hasFlight) ) {
                            if (column.key == 'RET') {
                                return true;
                            }
                            else if (this.getLevelKeys(true).includes(column.key)) {
                                return this.getLevelKeys().includes(column.key);
                            } else if (column.key === 'ADVERTISER') {
                                return _.find(this.report.levels, function(o) { return o.key === 'PROD'; });
                            } else if(this.CREAT_COLUMNS.indexOf(column.key) > -1) {
                                return this.hasCreative;
                            }
                            else if (this.FLIGHT_COLUMNS.indexOf(column.key) > -1) {
                                return this.hasFlight;
                            }
                            else if (this.ACCOUNT_COLUMNS.indexOf(column.key) > -1) {
                                return this.hasFlight || this.hasAdvertiser || this.hasCampaign || this.hasAdGroup;
                            }
                            else {
                                return this.hasAdGroup;
                            }
                        } else if (this.isSalesPerformance()) {
                            return true;
                        }
                        return false;
                    }
                    // section level
                    return true;

                } else if(type === 'd') {
                    if (column && column.key == 'B_UNIQUES') {
                        return true;
                    }
                }
                return true;
            },
            canRemoveLevel: function(l) {
                const isRequired = levels.findIndex(x => x.required && x.key == l.key) > -1;
                return !isRequired;
            },
            addLevel:function(level)
            {
                if(!this.canAddLevel(level)){
                    return;
                }
                this.isDirty = true;

                if ('SEG' == level.key)
                    this.report.sortOrder = this.getReportType().sortOrder;
                if ('ACC' == level.key)
                {
                    this.hasAdvertiser = true;
                    //ACC is the only level
                    if (this.report.levels.length == 0) {
                        //if report contain any refinement columns -remove them from the report.
                        this.removeRefineryColumns();
                    }
                }
                if (level.key == 'CAMP')
                    this.hasCampaign = true;
                if (level.key == 'AG')
                    this.hasAdGroup = true;
                if(level.key === 'SEG') {
                    this.hasSegment = true;
                }
                if(level.key === 'CREAT'){
                    this.hasCreative = true;
                }
                if(level.key === 'FLIGHT'){
                    this.hasFlight = true;
                }
                if(level.key === 'MARK') {
                    this.hasMarketer = true;
                }

                addToCollection(this.levels, this.report.levels, level);
                if(['ChannelID', 'UNIT', 'PLC'].includes(level.key)) {
                    this.applyChannelSpecificOptions();
                }
                this.report.columnOrder.headers.push({ name: level.name, key: level.key, type: 'level', format: level.format });
                this.validate();

            },
            addDimension: function (dim) {
                if (!this.canAddDimension(dim))
                    return;
                if (dim.key === 'H') {
                    this.hasHourDim = true;
                }
                this.isDirty = true;
                addToCollection(this.dimensions, this.report.dimensions, dim);
                this.report.columnOrder.headers.push({ name: dim.name, key: dim.key, type: 'dimension', format: dim.format });
                this.validate();
            },
            addColumn: function (col) {
                if (!this.canAddColumn(col.type, null, col))
                    return;
                this.isDirty = true;
                addToCollection(this.columns, this.report.columns, col);
                this.report.columnOrder.columns.push({ name: col.name, key: col.key, type: 'column', format: col.format });
                if(this.report.columnOrder.columns.length == 1) {
                    this.makeFirstSort();
                }
                this.validate();
            },
            removeRefineryColumns: function () {
                var refinements = this.report.columns.filter(function (x) { return x.type == 'r'; });
                var that = this;
                if (refinements != null) {
                    refinements.forEach(function (ref) {
                        that.removeColumn(ref);
                        that.removeItem(ref, 'columns');
                    });
                }
            },
            removeItem: function(data,type) {
                switch(data.type) {
                    case 'level':
                        if(this.canRemoveLevel(data)){
                            this.removeLevel(data);
                            this.isDirty = true;
                        } else {
                            return;
                        }
                        break;
                    case 'dimension':
                        this.removeDimension(data);
                        this.isDirty = true;
                        break;
                    case 'column':
                        this.removeColumn(data);
                        this.isDirty = true;
                        break;
                }
                //remove item from columnOrder
                var list = this.report.columnOrder[type];
                if (list) {
                    var col = list.find(function (y) { return y.key === data.key });
                    if (col) {
                        list.splice(list.indexOf(col), 1);
                    }
                }
                this.validate();
            },
            removeDimension: function(dim)
            {
                this.dimensions = _.clone(dimensions);
                if (this.report.sortOrder.sortColumn == dim.key)
                    this.report.sortOrder = this.getReportType().sortOrder;
                removeFromCollection(this.dimensions, this.report.dimensions, dim);
                if (dim.key === 'H') {
                    this.hasHourDim = false;
                }
                this.validate();
            },
            removeLevel: function (l) {
                this.levels = _.clone(levels);
                if (this.report.sortOrder.sortColumn === l.key)
                    this.report.sortOrder = this.getReportType().sortOrder;

                removeFromCollection(this.levels, this.report.levels, l);

                this.hasAdvertiser &&= l.key !== 'ACC';
                this.hasMarketer &&= l.key !== 'MARK';
                this.hasCampaign &&= l.key !== 'CAMP';
                this.hasAdGroup &&= l.key !== 'AG';
                this.hasSegment &&= l.key !== 'SEG';
                this.hasCreative &&= l.key !== 'CREAT';
                this.hasFlight &&= l.key !== 'FLIGHT';

                this.validate();

                const allLevelKeys = this.getLevelKeys(true);
                const levelKeys = this.getLevelKeys();
                const levelColumns = this.report.columnOrder.columns.filter(c => allLevelKeys.includes(c.key));

                levelColumns.forEach(column => {
                    if (!levelKeys.includes(column.key)) {
                        this.removeItem(column, 'columns');
                    }
                });

                if(l.key === 'PROD') {
                    var productAdvertiser = this.report.columnOrder.columns.find(c => c.key === 'ADVERTISER');
                    if (productAdvertiser) {
                        this.removeItem(productAdvertiser, 'columns');
                    }
                }

                if (['ChannelID', 'UNIT', 'PLC'].includes(l.key)) {
                    this.applyChannelSpecificOptions();
                }
            },
            removeColumn: function (c) {
                this.columns = _.clone(columns);
                removeFromCollection(this.columns, this.report.columns, c);
                removeFromCollection(this.columns, this.report.columnOrder.columns, c);

                if (this.report.sortOrder.sortColumn === c.key){
                    if(this.report.columns.length === 0){
                        this.report.sortOrder = this.getReportType().sortOrder;
                    } else {
                        this.makeFirstSort();
                    }

                }

                this.validate();
            },
            hasRangeDimention: function() {
                const rangeKey = this.report.dateFilter.filter;
                const range =  config.dateRanges.find(r => r.key === rangeKey);
                const dim = dimensions.find(d => d.key == range.dimention);
                return this.report.dimensions.find(d => d.key == dim.key) != null;
            },
            syncRange: function(rangeKey) {
                if(!rangeKey) {
                    rangeKey = this.report.dateFilter.filter;
                }
                if (!this.hasRangeDimention()) {
                    const range =  config.dateRanges.find(r => r.key.toLowerCase() === rangeKey.toLowerCase());
                    const dim = dimensions.find(d => d.key == range.dimention);
                    if(dim) {
                        this.addDimension(dim);
                    }
                }
            },
            getLevels: function () {
                var that = this;
                return this.levels.filter(function(it){ return filterByKeyword(it, that.keyword); });
            },
            getDimensions: function () {
                var that = this;
                return this.dimensions.filter(function(it){ return filterByKeyword(it, that.keyword); });
            },
            getColumns: function (type) {
                var columnSettings = config.tables.getOrderedColumns(this.isSiteReport() ? 'siteReports' : 'campaigns');
                this.columns.forEach(function (column) {
                    var columnSetting = columnSettings.find(function (cs) { return cs.key == column.key; });
                    if (columnSetting)
                        column.name = columnSetting.name;
                });

                //Incremented Sales columns
                var INCREMENTAL_SALES = ['INS', 'IROAS'];
                //Adjusted offline Sales columns
                var ADJUSTED_SALES = ['FAS', 'FASIS', 'ROAS', 'TAS', 'TIS', 'TROAS'];
                //Media columns
                var EXCLUDE_MEDIA_COLUMNS = ['PFEE', 'BIFEE', 'DCST', 'AGMMB', 'AGMMBC', 'MACPM', 'AGPT', 'AGRMB', 'AGRMMP', 'RMP', 'COM', 'AGRM', 'MCST', 'AGRMP', 'AGRCSD']

                var account = accountService.get();
                //do we need to display adj sales
                var adjustedSalesSet = true;
                //do we need to display incremental sales
                var salesLiftSet = true;

                var that = this;
                return this.columns.filter(function (item) {
                    if (!adjustedSalesSet) {
                        if (ADJUSTED_SALES.indexOf(item.key) !== -1) // exclude adjusted sales column if present and if option 9200 value != 1
                            return false;
                    }
                    if (!salesLiftSet)
                        if (INCREMENTAL_SALES.indexOf(item.key) !== -1)
                            return false;

                    return ((!item.type && !type) || item.type === type || item.type === `new_${type}` ) && config.tables.canShowColumnSystem(item.key);
                }).filter(function(it){ return filterByKeyword(it, that.keyword); });
            },
            validate: function()
            {
                var rType = this.reportType;

                this.isValid = ( this.report.columns.length > 0) &&
                    this.report.levels.length > 0 &&
                    this.report.dateFilter &&
                    (this.report.dateFilter.filter ||
                            (this.report.dateFilter.filter === 'ExactRange' &&
                                this.report.dateFilter.startDate &&
                                this.report.dateFilter.endDate
                            )) &&
                    (rType &&
                        (rType.type == 6 || rType.type == reportTypesEnum.custom.type ||
                            (rType.type == reportTypesEnum.sellPerformance.type) && this.report.dimensions.length >0 ||
                            (rType.type == reportTypesEnum.sitePerformance.type) && this.report.dimensions.length >0));
            },
            canSave:function() {
                return this.isValid && this.info.name;

            },
            isSalesPerformance: function() {
                return ($location.path().indexOf('/reporting/sales-performance') >= 0);
            },
            hasSellReport: function() {
                const hasPublisherFlag = accountService.hasOption(10120, true) || authService.isSuperAdmin();
                return hasPublisherFlag;
            },
            applyChannelSpecificOptions: function(filterSection = undefined) {
                if (!this?.isSellSideReport()){
                    return;
                }

                this.toggleSellSideMetrics(filterSection);
            },
            toggleSellSideMetrics: function(filterSection = undefined) {
                const availableColumns = ['RPM','REV', 'IMP', 'DSRev', 'CLC'];
                const availableLevels = ['PUB', 'MARK', 'ChannelID'];
                const mediaTypeConflictingLevels = ['ChannelID'];
                const conflictingLevels = ['PLC', 'UNIT'];
                const isChannelLevelApplied = this.report.levels.some(l => l.key === 'ChannelID');
                let isMediaTypeFilterApplied = this.report.filter.mediaTypes?.ids?.length > 0;
                let isChannelFilterApplied = (_.intersection([19, 20], this.report.filter.channels?.ids ?? [])).length > 0;
                let shouldDisableMetrics = isChannelFilterApplied || isChannelLevelApplied;

                if (filterSection === 'mediaTypes'  && isMediaTypeFilterApplied && shouldDisableMetrics) {
                    this.report.filter.channels.ids = [16];
                    isChannelFilterApplied = false;
                }

                if (filterSection === 'channels' && shouldDisableMetrics) {
                    this.report.filter.mediaTypes.ids = [];
                    isMediaTypeFilterApplied = false;
                }

                shouldDisableMetrics = isChannelFilterApplied || isChannelLevelApplied;

                if (isChannelFilterApplied) {
                    const notAvailableColumns = this.report.columns.filter(c => !availableColumns.includes(c.key));
                    notAvailableColumns.forEach(c => this.removeColumn(c));
                }
                else {
                    const dsRevAppliedColumn = this.report.columns.find(c => c.key === 'DSRev');
                    if (dsRevAppliedColumn){
                        this.removeColumn(dsRevAppliedColumn);
                    }
                }

                const isConflictingToChannelMetricApplied = isMediaTypeFilterApplied || (_.intersection(conflictingLevels, this.report.levels.map(l => l.key))).length > 0
                const channelLevel = this.levels.find(l => l.key === 'ChannelID');
                if (channelLevel) {
                    channelLevel.disabled = isConflictingToChannelMetricApplied;
                }

                this.columns
                    .filter(c => !availableColumns.includes(c.key))
                    .forEach(column => column.disabled = shouldDisableMetrics);
                this.levels
                    .filter(l => !availableLevels.includes(l.key))
                    .forEach(level => level.disabled = shouldDisableMetrics);

                const shouldDisableDirectSoldRevenue = !isChannelFilterApplied;
                const dsRevColumn = this.columns.find(c => c.key === 'DSRev');
                if (dsRevColumn) {
                    dsRevColumn.disabled = shouldDisableDirectSoldRevenue;
                }

                if (shouldDisableMetrics) {
                    this.report.filter.mediaTypes.ids = [];
                }
            },
            isSellSideReport: function () {

                const result =  $location.path().indexOf('/reporting/sell') >= 0;
                if(result) {

                    if(!this.hasSellReport()) {
                        $location.path('pages/403');
                        return false;
                    }
                    return true;
                }
                return false;
            },
            isSiteReport: function () {

                const result =  $location.path().indexOf('/reporting/site') >= 0;
                return result;
            },
            getReportType: function() {
                if(this.isSellSideReport()){
                    return reportTypesEnum.sellPerformance;
                }
                else if (this.isSiteReport()) {
                    return reportTypesEnum.sitePerformance;
                }
                else {
                    return reportTypesEnum.custom;
                }
            },
            isDirty: true,
            resetReport: function()
            {
                this.keyword = '';
                this.hasHourDim = false;
                var reportType = this.getReportType();
                if(reportType.reportProps){
                    levels = reportType.reportProps.levels;
                    columns = reportType.reportProps.columns;
                }
                this.ranges = this.getRanges().filter(x => x.key !== 'AllTime');
                if(reportType.reportProps && reportType.reportProps.dimensions) {
                    this.dimensions = _.clone(reportType.reportProps.dimensions);
                } else {
                    this.dimensions = _.clone(activeDimensions());
                }
                if(reportType.type == reportTypesEnum.sitePerformance.type) {
                    this.ranges = this.ranges.filter(x =>this.dimensions.some(y => x.key !='Today' &&  y.key == x.dimention));
                }
                this.levels = _.clone(levels);
                this.columns = _.clone(columns);
                dimensions = _.clone(this.dimensions);

                this.report = getEmptyReport();
                this.levels.filter(x => x.required).forEach(x => this.addLevel(x));
                setTimeout(() => {
                    if(this.info.id <= 0) {
                        this.syncRange(this.report.dateFilter.filter);
                    }
                });
                this.report.sortOrder = reportType.sortOrder;
                this.hasMarketer = this.hasFlight = this.hasCreative = this.hasAdGroup = this.hasAdvertiser = this.hasCampaign = this.hasSegment = false;

                this.info.type = reportType.type;
                this.reportType = reportType;
                this.validate();
                if (this.isSellSideReport()) {
                    this.applyChannelSpecificOptions();
                }
            },
            setFilter: function() {
                if (!this.filter) {
                    var reportType = this.getReportType();
                    this.filter = getEmptyFilter(reportType?.type);
                    var filterConfig = reportType.filterConfig;
                    if (filterConfig) {
                        for (var key of Object.keys(this.filter)){
                            if (typeof this.filter[key] == "object" && filterConfig[key]) {
                                this.filter[key].on = filterConfig[key] ? filterConfig[key].on : false;
                            }
                        }
                    }


                }

                if (this.isSellSideReport() && this.report && this.report.filter && this.report.filter.channels) {
                    this.report.filter.channels.ids = sellPerformanceReportProps.defaultFilters.channels;
                }

                return this.filter;

            },
            getRanges: function() {
                //filter out features that are not included
                const reportType = this.getReportType().type;
                return config.dateRanges.filter(function (item) {
                    //DO NOT SUPPORT ROLLING UPDATES

                    if ((item.key === 'Last7Days' || item.key === 'Last30Days' || item.key === 'Last90Days') && reportType !== 8)
                        return false;
                    return !item.feature || authService.hasFeature(item.feature);
                    });
            }
        };
        return service;
    }
})();
