import './setPublicPath'
import "./styles/plugins/rzslider.scss";
import "./styles/main.scss";

import "./scripts/utils";
import "./scripts/app.module";
import "./scripts";
import { hideLoader } from './scripts/utils/loader'

import singleSpaAngularJS from "single-spa-angularjs";

const ngLifecycles = singleSpaAngularJS({
  angular: angular,
  mainAngularModule: "app",
  ngRoute: true,
  preserveGlobal: false,
  template: "<root />",
});

export const bootstrap = ngLifecycles.bootstrap;
export const mount = ngLifecycles.mount;
export const unmount = [
  async () => {
    document.body.style = ""
  },
  async () => {
    hideLoader('mf')
  },
  ngLifecycles.unmount
];
