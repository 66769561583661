(function () {
  'use strict';

  angular.module('app.page')
    .directive('customPage', customPage);


  // add class for specific pages to achieve fullscreen, custom background etc.
  function customPage() {
    var directive = {
      restrict: 'A',
      controller: ['$scope', '$rootScope', '$element', '$location', 'appCache', customPageCtrl]
    };

    return directive;

    function customPageCtrl($scope, $rootScope, $element, $location) {
      var addBg, path;

      path = function () {
        return $location.path();
      };


      addBg = function (path) {
        const noTogglerClass = 'no-toggler-sidebar';
        const noSideBarClass = 'no-sidebar';

        $element.removeClass(`wizard-header body-wide body-err body-lock body-auth ${noSideBarClass} ${noTogglerClass}`);

        if ($rootScope.rootVM.uiStyle === noSideBarClass)
          return $element.addClass(noSideBarClass);


        if (path === '/activation/campaign/campaign-wizard')
          $element.addClass('wizard-header');

        var noSideBar = [
          '/activation/campaign',
          '/activation/pixel',
          '/activation/retargeting-audience',
          '/activation/admin-only',
          '/admin/deals',
          '/reporting',
          '/activation/creative',
          '/activation/creative-request',
          '/activation/location',
          '/admin/users',
          '/admin/roles',
          '/admin/account-management',
          '/admin/accounts/advertisers',
          '/admin/accounts/advertisergroups',
          '/admin/accounts/marketers',
          '/admin/accounts/publishers',
          '/admin/accounts',
          '/admin/manufacturers',
          '/admin/holding-companies',
          '/settings/account-settings',
          '/activation/brand-safety/inventory-list',
          '/activation/brand-safety/keyword-list',
          '/activation/brand-safety/blocking-profile',
          '/activation/brand-safety',
          '/admin/accounts',
          '/admin/search',
          '/admin/tags',
          '/admin/taxonomy',
          '/pages/403',
          '/pages/404',
          '/pages/500',
          '/404',
          '/admin/reporting/performance-daily/',
          '/admin/settings',
          '/admin/features',
          '/reporting/match',
          '/activation/web-events',
          '/activation/web-events/pixel',
          '/activation/web-events/retargeting-audience',
          '/admin/web-events/deals',
        ];

        if (path) {
          noSideBar.forEach(function (item, idx) {
            if (path.indexOf(item) === 0) {
              return $element.addClass(noSideBarClass);
            }
          })
          const noToggler = [
            '/reporting/campaign-summary',
            '/reporting/custom',
            '/reporting/site',
            'reporting/sell'
          ].some(x => path.indexOf(x) > -1);
          if (noToggler) {
            $element.addClass(noTogglerClass);
            $element.removeClass(noSideBarClass);
            $element.removeClass('nav-collapsed-min');
          }
        }

        switch (path) {
          case '/404':
          case '/pages/404':
          case '/pages/403':
          case '/pages/500':
            return $element.addClass('body-wide body-err');
          case '/pages/change-password':
            return $element.addClass('body-wide body-auth');
        }
      };

      addBg($location.path());

      $scope.$watch(path, function (newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }

        return addBg($location.path());
      });
    }
  }

})();


