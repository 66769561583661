(function () {
    'use strict';
    angular.module('app')
        .directive('creativeUploadNew', ['$location', function () {
            return {
                template: require('@views/creative/editors/creative-upload-new.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { adGroup: '=', creatives: '=' },
                controller: ['$scope', '$rootScope', 'hub', 'jwplayerService', 'campaignService', 'creativeService', '$sce', '$timeout', 'Enum', 'authService', function ($scope, $rootScope, hub, jwplayerService, campaignService, creativeService, $sce, $timeout, Enum, authService) {
                    var vm = this;
                    vm.adGroup = $scope.adGroup;
                    vm.campaign = campaignService.getSelectedCampaign();
                    vm.creative = { thirdPartyCreativeType: '1' };
                    vm.creatives = $scope.creatives;
                    vm.hasAccess = authService.hasAccess;
                    vm.isObserver = authService.isObserver;

                    vm.sizes = campaignService.getSizes();
                    vm.selectedSize = vm.sizes[0];

                    vm.thirdPartyTypes = [{ id: '1', name: 'HTML' }];

                    vm.hover = function (creative, show) {
                        creative.showPopup = show;
                    }

                    vm.canSubmit = function () {
                        return vm.campaign.channelId == 2 && (vm.creative.nonSecureVASTValid || vm.creative.secureVASTValid)
                            || vm.campaign.channelId == 1 && (vm.creative.bannerURL || vm.creative.thirdPartyAdTag);
                    }

                    vm.cancelCreative = function () {
                        vm.creative = { thirdPartyCreativeType: '1' };
                        $rootScope.$broadcast('selectedImagesChanged', vm.creative);
                    }

                    vm.addCreative = function (creative) {

                        if (!creative)
                            creative = vm.creative;


                        if (!vm.adGroup.creatives)
                            vm.adGroup.creatives = [];

                        if (vm.campaign.channelId == 2) {
                            creative.playerId = guid();
                            creative.videoFile = $sce.trustAsResourceUrl(creative.videoURL);
                        }
                        creative.sensitiveCategoriesApply = false;
                        creative.showAuditAttributes = false;

                        if (!creative.provisionalAuditData)
                        {
                            hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                                creative.provisionalAuditData = angular.copy(l);
                            });
                        }
                        creative.channelId = vm.campaign.channelId;
                        creative.uploaded = true;
                        creative.checked = true;
                        creative.advertiserId = vm.campaign.advertiserId;
                        creative.marketerId = vm.campaign.marketerId;

                        if (!creative.guid)
                            creative.guid = guid();

                        if (vm.adGroup && vm.adGroup.audience && campaignService.marketers) {
                            var campaignMarketer = campaignService.marketers.filter(function (r) { return r.id == vm.adGroup.audience.marketerId; })[0];
                            if (campaignMarketer) {
                                var marketerHomePageURL = campaignMarketer.options.filter(function (o) { return o.optionId == 9044 })[0];
                                if (marketerHomePageURL) {
                                    creative.destinationURL = marketerHomePageURL.value;
                                }
                            }
                        }

                            vm.creatives.splice(0, 0, creative);
                            vm.adGroup.creatives.push(creative);
                            $rootScope.$broadcast('selectedImagesChanged', creative);
                            vm.creative = { thirdPartyCreativeType: '1' };
                    }

                    vm.creativeNameChanged = function (creative) {

                        creative.duplicateName = false;
                        if (!creative.name)
                            return;

                        if (creative.advertiserId) {
                            var validationData = {};
                            validationData.entityName = creative.name;
                            validationData.entityType = "creative";
                            validationData.advertiserId = creative.advertiserId;
                            validationData.creativeId = creative.id
                            hub.webapicontext.validateName(validationData).then(function (data) {
                                if (data.message !== "Valid Name")
                                    creative.duplicateName = true;
                            });
                        }
                    }

                    vm.videoOptions = {};

                    vm.removeCreative = function (creative) {
                        creative.checked = false;
                        vm.adGroup.creatives.splice($.inArray(creative, vm.adGroup.creatives), 1);
                        vm.creatives.splice($.inArray(creative, vm.creatives), 1);
                    }

                    vm.uploadImage = function (file, dimensions, bannerAttributes, warning) {
                        vm.wrongDimension = false;
                        return hub.webapicontext.uploadFile('Creative/ImageUpload', file).then(function (data) {
                            var creative = {};
                            creative.size = dimensions;
                            creative.bannerSize = Enum.getId('BannerSizes', dimensions);
                            creative.width = dimensions.substring(0, dimensions.indexOf('x'));
                            creative.height = dimensions.substring(dimensions.indexOf('x') + 1);
                            creative.bannerURL = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                            if (warning)
                                creative.warning = warning;
                            else
                                creative.warning = null;


                            if (bannerAttributes) {
                                hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                                    creative.provisionalAuditData = angular.copy(l);
                                    creative.technicalAttributes = bannerAttributes;
                                    creativeService.fillSetAuditData(creative);
                                    vm.addCreative(creative);
                                });
                            }
                            else {
                                hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                                    creative.provisionalAuditData = angular.copy(l);
                                    vm.addCreative(creative);
                                });
                            }
                        });
                    }

                    $scope.$on('wrongDimension', function (event, data) {
                        vm.dimensionMessage = data.message;
                        $timeout(function () {
                            vm.wrongDimension = true;
                        });
                    });

                    vm.uploadVideo = function (file, thumbURL) {
                        return hub.webapicontext.uploadFile('Creative/ImageUpload', file).then(function (data) {
                            var creative = {};
                            creative.videoURL = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                            creative.creativeIconURL = thumbURL;
                            $scope.videoFile = $sce.trustAsResourceUrl(creative.videoURL);
                            vm.videoOptions.image = thumbURL;
                            $rootScope.$broadcast("videoUploaded", $scope.videoFile);
                            vm.addCreative(creative);
                        });
                    }

                    vm.thirdPartyAdTagChanged = function (size) {
                        vm.creative.thirdPartyAdTagUrl = null;
                        $timeout(function () {
                            vm.creative.height = '';
                            vm.creative.width = '';
                            vm.creative.size = 'All Sizes';
                            if (!size && vm.creative.thirdPartyAdTag && vm.creative.thirdPartyAdTag.indexOf('<ins') > -1) {
                                if (vm.creative.thirdPartyAdTag.match(/height:(\d)*/g))
                                    vm.creative.height = vm.creative.thirdPartyAdTag.match(/height:(\d)*/g)[0].substring(7);
                                if (vm.creative.thirdPartyAdTag.match(/width:(\d)*/g))
                                    vm.creative.width = vm.creative.thirdPartyAdTag.match(/width:(\d)*/g)[0].substring(6);
                                if (vm.creative.height && vm.creative.width)
                                    vm.creative.size = vm.creative.width + 'x' + vm.creative.height;
                                if (!vm.sizes.find(function (s) { return s.name == vm.creative.size; })) {
                                    vm.creative.height = '';
                                    vm.creative.width = '';
                                    vm.creative.size = 'All Sizes';
                                }
                            }
                            else if (!size && vm.creative.thirdPartyAdTag && vm.creative.thirdPartyAdTag.indexOf('${CLICK_URL}') > -1) {
                                if (vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)) {
                                    var img = new Image();
                                    img.onload = function () {
                                        var allowedDimensions = ['728x90', '300x600', '300x250', '160x600', '320x50', '300x50', '970x90', '970x250', '1x1', '460x120', '570x70', '120x240', '650x136', '1200x628', '320x480', '640x480', '640x640', '120x600', '1920x1080', '768x1024', '1024x768', '320x100', '300x1050', '250x250', '336x280', '480x320', '320x250', '970x550', '468x60'];
                                        if (allowedDimensions.indexOf(this.width + 'x' + this.height) >= 0) {
                                            vm.creative.size = this.width + 'x' + this.height;
                                            vm.creative.height = this.height;
                                            vm.creative.width = this.width;
                                            vm.creative.bannerURL = vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                            if (vm.creative.size != 'All Sizes') {
                                                vm.creative.bannerSize = Enum.getId('BannerSizes', vm.creative.size);
                                            }
                                            if (typeof vm.creative.size === "string") {
                                                for (var i = 0; i < Enum.BannerSizes.length; i++) {
                                                    if (vm.creative.name && vm.creative.name.endsWith(Enum.BannerSizes[i].name)) {
                                                        vm.creative.name = vm.creative.name.split(Enum.BannerSizes[i].name).join('');
                                                        break;
                                                    }
                                                }
                                                if (typeof vm.creative.name === 'undefined' || vm.creative.name === 'All Sizes')
                                                    vm.creative.name = '';

                                                vm.creative.name += vm.creative.size;
                                            }
                                        }
                                        else
                                            alert("Wrong dimensions!");
                                    }
                                    img.src = vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                }

                            }
                            else {
                                vm.creative.width = size.substring(0, size.indexOf('x'));
                                vm.creative.height = size.substring(size.indexOf('x') + 1);
                                vm.creative.size = size;
                            }
                            if (vm.creative.size != 'All Sizes') {
                                vm.creative.bannerSize = Enum.getId('BannerSizes', vm.creative.size);
                            }
                            vm.creative.thirdPartyAdTagUrl = $sce.trustAsResourceUrl(vm.creative.thirdPartyAdTag);
                            vm.creative.guid = guid();

                            if (typeof vm.creative.size === "string") {
                                for (var i = 0; i < Enum.BannerSizes.length; i++) {
                                    if (vm.creative.name && vm.creative.name.endsWith(Enum.BannerSizes[i].name)) {
                                        vm.creative.name = vm.creative.name.split(Enum.BannerSizes[i].name).join('');
                                        break;
                                    }
                                }

                                if (typeof vm.creative.name === 'undefined' || vm.creative.name === 'All Sizes')
                                    vm.creative.name = '';

                                vm.creative.name += vm.creative.size;
                            }

                            if (typeof vm.creative.size === "undefined") {
                                vm.creative.name = '';
                            }
                        });
                    }

                    vm.player = null;
                    vm.videoOptions = {};
                    $scope.$on('ng-jwplayer-ready', function (event, args) {

                        // Get player from service
                        vm.player = jwplayerService.myPlayer[args.playerId];
                        vm.processingSecure = false;
                        vm.processingNonSecure = false;

                        vm.player.once('error', function (event) {
                            HandlePlayerError(event.message);
                        });

                        vm.player.once('time', function () {
                            vm.creative.videoDuration = vm.player.getDuration();
                            vm.player.pause();
                        });

                        vm.player.play();
                    });

                    $scope.$on('ng-jwplayer-setup-error', function (event, args) {
                        HandlePlayerError();
                    });

                    function HandlePlayerError(message) {
                        if (vm.selectedVAST == 'nonsecure') {
                            vm.processingNonSecure = false;
                            vm.creative.nonSecureVASTValid = false;
                            vm.nonSecureVASTErrorReason = message ? message : 'Invalid video file';
                            vm.selectedSecureVASTURLChanged();
                        }

                        if (vm.selectedVAST == 'secure') {
                            vm.processingSecure = false;
                            vm.creative.secureVASTValid = false;
                            vm.secureVASTErrorReason = message ? message : 'Invalid video file';
                        }
                    }

                    vm.getChannelById = function (channelId) {
                        var name = '';
                        vm.channels.forEach(function (item, index) {
                            if (item.id == channelId)
                                name = item.name;
                        });
                        return name;
                    }

                    vm.selectedVAST = '';

                    vm.selectedSecureVASTURLChanged = function () {
                        if (!vm.creative.secureVASTDocumentURL) {
                            vm.creative.secureVASTValid = null;
                            if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                vm.creative.videoURL = null;
                        } else if (vm.creative.secureVASTDocumentURL && !vm.creative.secureVASTDocumentURL.startsWith("https://")) {
                            vm.creative.secureVASTValid = false;
                            vm.secureVASTErrorReason = "Secure VAST document URL should start with https";
                            if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                vm.creative.videoURL = null;
                        }
                        else if (vm.creative.secureVASTDocumentURL && vm.creative.secureVASTDocumentURL.startsWith("https://")) {
                            vm.processingSecure = true;
                            hub.webapicontext.validateVASTURL(vm.creative.secureVASTDocumentURL).then(function (data) {
                                if (data.url) {
                                    vm.creative.secureVASTValid = true;
                                    if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid) {
                                        vm.creative.videoURL = data.url;
                                        $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);
                                        $rootScope.$broadcast("videoUploaded", $scope.videoFile);
                                        vm.selectedVAST = 'secure';
                                    }
                                    else {
                                        vm.processingSecure = false;
                                    }
                                }
                                else {
                                    vm.secureVASTErrorReason = data.message;
                                    vm.processingSecure = false;
                                    vm.creative.secureVASTValid = false;
                                    if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                        vm.creative.videoURL = null;
                                }
                            });
                        }
                    }

                    vm.selectedNonSecureVASTURLChanged = function () {
                        if (!vm.creative.nonSecureVASTDocumentURL) {
                            vm.creative.nonSecureVASTValid = null;
                            if (vm.selectedVAST != 'secure') {
                                if (vm.creative.secureVASTDocumentURL) {
                                    vm.selectedSecureVASTURLChanged();
                                }
                                else {
                                    vm.creative.videoURL = null;
                                }
                            }
                        } else if (vm.creative.nonSecureVASTDocumentURL && !vm.creative.nonSecureVASTDocumentURL.startsWith("http://")) {
                            vm.creative.nonSecureVASTValid = false;
                            vm.nonSecureVASTErrorReason = "Unsecure VAST document URL should start with http";
                        } else if (vm.creative.nonSecureVASTDocumentURL && vm.creative.nonSecureVASTDocumentURL.startsWith("http://")) {
                            vm.processingNonSecure = true;
                            vm.player = null;
                            hub.webapicontext.validateVASTURL(vm.creative.nonSecureVASTDocumentURL).then(function (data) {
                                if (data.url) {
                                    vm.creative.nonSecureVASTValid = true;
                                    vm.creative.videoURL = data.url;
                                    $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);
                                    $rootScope.$broadcast("videoUploaded", $scope.videoFile);
                                    vm.selectedVAST = 'nonsecure';
                                }
                                else {
                                    vm.creative.nonSecureVASTValid = false;
                                    vm.nonSecureVASTErrorReason = data.message;
                                    vm.creative.videoURL = null;
                                    vm.processingNonSecure = false;
                                    if (vm.creative.secureVASTDocumentURL)
                                        vm.selectedSecureVASTURLChanged();
                                }
                            });
                        }
                    }

                    function guid() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000)
                              .toString(16)
                              .substring(1);
                        }
                        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                          s4() + '-' + s4() + s4() + s4();
                    }

                    return vm;
                }]
            }
        }]);
})();
