(function () {
    'use strict';

    var serviceId = 'webapicontext';
    angular.module('app').factory(serviceId, ['$q', '$http', 'appSettings', 'Upload', 'appCache', 'api', 'config', 'expiringAppCache', webapicontext]);


    function webapicontext($q, $http, appSettings, Upload, appCache, api, config, expiringAppCache) {


        //service definition
        var service = {
            getRoles: getRoles,
            manageRoles: manageRoles,
            getUsersByAccountId: getUsersByAccountId,
            API_SERVER_URL: appSettings.apiUrl,
            filters: getFilters,
            getCampaigns: getCampaigns,
            getCampaign: getCampaign,
            getCampaignsList: getCampaignsList,
            saveCampaign: saveCampaign,
            copyCampaign: copyCampaign,
            removeCampaign: removeCampaign,
            removeCampaigns: removeCampaigns,
            pauseCampaign: pauseCampaign,
            restartCampaign: restartCampaign,
            pauseRestartMultiple: pauseRestartMultiple,
            copyMultiple: copyMultiple,
            saveMarketerCampaign: saveMarketerCampaign,
            getMarketerCampaigns: getMarketerCampaigns,

            updateadGroupHiddenOptions: updateadGroupHiddenOptions,
            saveAdGroup: saveAdGroup,
            saveReportingAdGroup: saveReportingAdGroup,
            copyAdGroup: copyAdGroup,
            copyEditAdGroup: copyEditAdGroup,
            removeAdGroup: removeAdGroup,
            removeAdGroups: removeAdGroups,
            pauseAdGroup: pauseAdGroup,
            restartAdGroup: restartAdGroup,
            getAdGroupsFlights: getAdGroupsFlights,
            getAudiences: getAudiences,
            searchAudiences: searchAudiences,
            searchAudiencesWithParents: searchAudiencesWithParents,
            saveAudience: saveAudience,
            pushDmpAudience: pushDmpAudience,
            copyAudience: copyAudience,
            removeAudience: removeAudience,
            removeLocation: removeLocation,
            getCreativeHistory: getCreativeHistory,
            getCreative: getCreative,
            getCreatives: getCreatives,
            saveCreative: saveCreative,
            saveBulkCreatives: saveBulkCreatives,
            saveBulkAdGroups: saveBulkAdGroups,
            validateDOAGDates: validateDOAGDates,
            resendBulkCreatives: resendBulkCreatives,
            resendBulkCampaigns: resendBulkCampaigns,
            refreshThumbnail: refreshThumbnail,
            copyCreative: copyCreative,
            removeCreative: removeCreative,
            saveAccountOptions: saveAccountOptions,
            saveMarketerContract: saveMarketerContract,
            saveDirectSoldFees: saveDirectSoldFees,
            getAccounts: getAccounts,
            getAccount: getAccount,
            saveAccount: saveAccount,
            saveMarketerFbAttribution: saveMarketerFbAttribution,
            getUsers: getUsers,
            saveUser: saveUser,
            uploadFile: uploadFile,
            uploadData: uploadData,
            uploadAdminOnlyFile: uploadAdminOnlyFile,
            removeFile: removeFile,
            getSegments: getSegments,
            getBrandSafetySegments: getBrandSafetySegments,
            getMatchReportTypes: getMatchReportTypes,
            estimateAudienceReach: estimateAudienceReach,
            setAccountStatus: setAccountStatus,
            getMarketersNames: getMarketersNames,
            getAPBScript: getAPBScript,
            getAdvertisersWithCampaigns: getAdvertisersWithCampaigns,
            validateVASTURL: validateVASTURL,
            feValidateVASTURL: feValidateVASTURL,
            validateVASTXML: validateVASTXML,
            contractsEditable: contractsEditable,
            validateName: validateName,
            validateAdGroupIdsList: validateAdGroupIdsList,
            validateSourceAdGroupIdsList: validateSourceAdGroupIdsList,

            processRequestCreative: processRequestCreative,
            submitRequestCreative: submitRequestCreative,
            getCreativeRequest: getCreativeRequest,
            getCreativeRequests: getCreativeRequests,

            getInventoryLists: getInventoryLists,
            getInventoryList: getInventoryList,
            saveInventoryList: saveInventoryList,
            removeInventoryList: removeInventoryList,
            copyInventoryList: copyInventoryList,
            getKeywordLists: getKeywordLists,
            getKeywordList: getKeywordList,
            saveKeywordList: saveKeywordList,
            removeKeywordList: removeKeywordList,
            copyKeywordList: copyKeywordList,
            saveBlockingProfile: saveBlockingProfile,
            getBlockingProfiles: getBlockingProfiles,
            getBlockingProfile: getBlockingProfile,
            removeBlockingProfile: removeBlockingProfile,
            copyBlockingProfile: copyBlockingProfile,
            downloadFileFromUrl: downloadFileFromUrl,
            downloadFileFromUrlSafari: downloadFileFromUrlSafari,
            removeImageSafari: removeImageSafari,
            getFbPreview: getFbPreview,

            migrateAdGroups: migrateAdGroups,
            accountManagers: getAccountManagers,
            getCsvPreview: getCsvPreview,
            validateTag: validateTag,
            searchDeals: searchDeals,
            saveDeal: saveDeal,
            getConceptGroups: getConceptGroups,
            getPublishers: getPublishers,
            checkAdGroupDataCpmCalculationIsLegacy: checkAdGroupDataCpmCalculationIsLegacy,
            getTotalCpm,
        };

        return service;

        function getFilters() {
            return JSON.parse(localStorage['upcFilters']);
        }

        function validateVASTURL(src) {
            return api.post('api/utils/validatevast', "'" + src + "'");
        }

        function validateVASTXML(src) {
            return api.post('api/utils/validatevastxml', "'" + src + "'");
        }

        function feValidateVASTURL(url) {
            return $http.get(url);
        }

        function contractsEditable() {
            return api.get('api/utils/contractseditable');
        }

        function validateName(validationData) {
            return api.post('api/utils/validatename', validationData);
        }

        function validateAdGroupIdsList(validationData) {
            return api.post('api/utils/validateadgroupidslist', validationData);
        }

        function validateSourceAdGroupIdsList(validationData) {
            return api.post('api/adgroup/validatesourceadgroupidslist', validationData);
        }

        function getUsers() {

            return api.get('api/User/');
        }
        function getAccountManagers() {
            return expiringAppCache.get({
                url: 'api/User/accountManagers',
            });
        }

        function setAccountStatus(obj) {

            return api.post('api/CompanyAccount/setAccountStatus', obj);
        }

        function getUsersByAccountId(id) {

            return api.get('api/User/GetByAccountId/' + id);
        }

        function getAccounts(reload) {

            return api.getCached('api/companyAccount/all', 'accounts', reload)
        }

        function getAccount(id, fromCache) {

            return api.getCached('api/companyAccount/' + (id ? id : ''), 'account', fromCache ? false : true)
        }

        function getAdvertisersWithCampaigns() {
            return expiringAppCache.get({
                url: 'api/companyAccount/AdvertisersWithCampaigns',
            });
        }

        function getRoles(type) {
            if (!type)
                return api.getCached('api/User/roles/', 'roles');
            else
                return api.getCached('api/User/roles/type/' + type, 'roles_' + type);
        }

        function manageRoles() {
            return api.get('api/User/manageRoles/');
        }

        function saveAccountOptions(options) {
            return api.post('api/CompanyAccount/accountOptions', options);
        }

        function saveMarketerContract(marketerContract) {
            return api.post('api/CompanyAccount/marketerContract', marketerContract);
        }

        function saveDirectSoldFees(fees) {
            return api.post('api/CompanyAccount/directSoldFees', fees);
        }

        function saveMarketerFbAttribution(model) {
            return api.post('api/CompanyAccount/marketerFbAttribution', model);
        }

        function saveUser(user) {

            return api.post('api/user/', user);
        }

        function saveAccount(account, isAdvertiserManager, isPublisherManager) {
            if (isAdvertiserManager && account.accountType === 1)
                return api.post('api/CompanyAccount/advertiser', account);
            if (isPublisherManager && account.accountType === 5)
                return api.post('api/CompanyAccount/publisher', account);
            else
                return api.post('api/CompanyAccount', account);
        }

        function getCampaigns(id) {
            return api.get('api/Campaign/');
        }

        function getCampaign(id, adGroupId) {
            if (id)
                return api.get('api/Campaign/' + id);
            else if (adGroupId)
                return api.get('api/Campaign/CampaignByAdGroupID/' + adGroupId);

        }

        function getCampaignsList() {
            return api.get('api/Campaign/list-jst');
        }

        function clearCache() {
            expiringAppCache.clear();
        }

        function saveCampaign(campaign, isMeasurementOnly) {
            clearCache();
            if (isMeasurementOnly)
                return api.post('api/Campaign/measurement', campaign);
            else
                return api.post('api/Campaign/', campaign);
        }

        function copyCampaign(id) {
            clearCache();
            return api.get('api/Campaign/copy/' + id);
        }

        function removeCampaign(id) {
            clearCache();
            return api.delete('api/Campaign/' + id);
        }

        function removeCampaigns(ids) {
            clearCache();
            if (ids && ids.length == 1)
                return api.delete('api/Campaign/' + ids[0]);
            else
                return null;
        }

        function pauseCampaign(id) {
            clearCache();
            return api.get('api/Campaign/pause/' + id);
        }

        function restartCampaign(id) {
            clearCache();
            return api.get('api/Campaign/restart/' + id);
        }

        function pauseRestartMultiple(action, ids, entityType) {
            var param;
            if (entityType === 'segment') {
                param = { action: action, segments: ids };
                return api.post('api/adgroup/pauserestartmultiplesegments', param);
            }
            else {
                param = { action: action, ids: ids };
                return api.post('api/' + entityType + '/pauserestartmultiple', param);
            }
        }

        function getAdGroupsFlights(ids) {
            return api.post('api/AdGroup/adgroupsflights', ids);
        }

        function copyMultiple(ids, entityType) {
            clearCache();
            var param = { action: 'copy', ids: ids };
            return api.post('api/' + entityType + '/copymultiple', param);
        }

        function saveMarketerCampaign(campaign) {
            return api.post('api/audience/savemarketercampaign', campaign);
        }

        function getMarketerCampaigns(accountId) {
            return api.get('api/audience/getmarketercampaigns');
        }

        function getAPBScript(id) {
            return api.get('api/AdGroup/apbscript/' + id);
        }

        function updateadGroupHiddenOptions(adGroup) {
            return api.post('api/AdGroup/updateadGroupHiddenOptions', adGroup);
        }

        function saveAdGroup(adGroup, isMeasurementOnly) {
            clearCache();

            if (isMeasurementOnly && adGroup.channelId !== 9)
                return api.post('api/AdGroup/measurement', adGroup);
            else
                return api.post('api/AdGroup/', adGroup);
        }

        function saveReportingAdGroup(adGroup) {
            clearCache();
            return api.post('api/AdGroup/savereportingadgroup', adGroup);
        }

        function copyAdGroup(id) {
            clearCache();
            return api.get('api/AdGroup/copy/' + id);
        }

        function copyEditAdGroup(adGroup) {
            return api.post('api/AdGroup/copyEdit', adGroup);
        }

        function removeAdGroup(id) {
            clearCache();
            return api.delete('api/AdGroup/' + id);
        }

        function removeAdGroups(ids) {
            clearCache();
            return api.post('api/AdGroup/removeMultiple', ids);
        }

        function pauseAdGroup(id) {
            clearCache();
            return api.get('api/AdGroup/pause/' + id);
        }

        function restartAdGroup(id) {
            clearCache();
            return api.get('api/AdGroup/restart/' + id);
        }

        function getAudiences(audienceType, marketerId, isAudiencePage, channelId, advertiserId, page) {
            if (audienceType === 'Custom')
                return api.get('api/audience/?pageNumber=' + page + '&audienceType=Custom' + (marketerId ? '&marketerId=' + marketerId : '') + (advertiserId ? '&accountId=' + advertiserId : '') + (isAudiencePage ? '&isAudiencePage=true' : ''));
            else if (audienceType === 'InventoryByPublisher' || audienceType === 'InventoryByCategory')
                return api.get('api/Segments/connectedtvinventory?channelId=' + channelId + (marketerId ? '&marketerId=' + marketerId : '') + (advertiserId ? '&advertiserId=' + advertiserId : ''));
            else
                return api.get('api/audience/?marketerId=' + (marketerId ? marketerId : '') + (advertiserId ? '&accountId=' + advertiserId : '') + (audienceType ? '&audienceType=' + audienceType : '') + (isAudiencePage ? '&isAudiencePage=true' : ''));
        }
        function searchAudiences({ audienceType = null, marketerId = null, isAudiencePage = false, channelId = null, advertiserId = null, keyword = null, showInternal = null, hasMeta = null, id = null, isPublicFilter = null }, pager) {
            if (audienceType === 'InventoryByPublisher' || audienceType === 'InventoryByCategory')
                return api.get('api/Segments/connectedtvinventory?channelId=' + channelId + (marketerId ? '&marketerId=' + marketerId : '') + (advertiserId ? '&advertiserId=' + advertiserId : ''));
            else {
                const filter = {
                    audienceType,
                    marketerId,
                    isAudiencePage,
                    channelId,
                    accountId: advertiserId,
                    keyword,
                    showInternal,
                    hasMeta,
                    id,
                    isPublicFilter
                };
                return api.post('api/audience/search', { filter, pager });
            }
        }

        function searchAudiencesWithParents({ audienceType = null, marketerId = null, isAudiencePage = false, channelId = null, advertiserId = null, keyword = null, showInternal = null, hasMeta = null, id = null, isPublicFilter = null, deepLevel = 1 }, pager) {
            const filter = {
                audienceType,
                marketerId,
                isAudiencePage,
                channelId,
                accountId: advertiserId,
                keyword,
                showInternal,
                hasMeta,
                id,
                isPublicFilter,
                deepLevel
            };
            return api.post('api/audience/audienceWithParents', { filter, pager });
        }

        function saveDeal(deal) {
            return api.post('api/deal', deal);
        }
        function searchDeals({ marketerId = null, isAudiencePage = false, channelId = null, advertiserId = null, keyword = null, }, pager) {
            const filter = {
                marketerId,
                isAudiencePage,
                channelId,
                advertiserId,
                keyword,
            };
            return api.post('api/deal/search', { filter, pager });
        }

        function getConceptGroups(filter) {
            return api.post('api/audience/search-concept-groups', filter);
        }

        function getPublishers() {
            return api.get('api/companyaccount/publishers/');
        }

        function checkAdGroupDataCpmCalculationIsLegacy(adGroupId) {
            return api.get('api/adgroup/check-legacy-datacpm-calc/' + adGroupId);
        }

        function saveAudience(audience) {
            return api.post('api/audience/', audience);
        }

        function pushDmpAudience(audience) {
            return api.post('api/audience/pushdmpaudience', audience);
        }

        function copyAudience(id, advertiserId) {
            if (advertiserId) {
                return api.get('api/Audience/copy?id=' + id + '&advertiserId=' + advertiserId);
            } else {
                return api.get('api/Audience/copy?id=' + id);
            }
        }

        function removeAudience(id) {
            return api.delete('api/Audience/' + id);
        }

        function removeLocation(id) {
            return api.delete('api/locationGroup/' + id);
        }

        function getMarketersNames() {
            return api.get('api/audience/marketers');
        }

        function getSegments(accountId, id, from, taxonomyId) {
            if (!from)
                from = 0;
            if (!taxonomyId) {
                return new Promise(function (resolve, reject) {
                    resolve([]);
                });
            }
            return api.get('api/Segments/' + taxonomyId + '/' + id + '/' + from);
        }

        function getBrandSafetySegments(id, from) {
            if (!id)
                id = -1;
            if (!from)
                from = 0;
            return api.get('api/Segments/brandsafety/' + id + '/' + from);
        }

        function getMatchReportTypes(marketerId) {
            return api.getCached('api/audience/matchreporttypes/' + marketerId, 'matchreporttypes');
        }

        function estimateAudienceReach(includedGroups, recencyFrom, recencyTo, taxonomyId, channelId, advertiserId) {
            var selectedSegmentIds = [];
            includedGroups.forEach(function (g) {
                g.segments.forEach(function (s) {
                    if (!s.excluded)
                        selectedSegmentIds.push(s.id);
                });
            });
            var params = { segmentIds: selectedSegmentIds, recencyFrom: recencyFrom, recencyTo: recencyTo, taxonomyId: taxonomyId, channelId: channelId, advertiserId: advertiserId };
            return api.post('api/Segments/reach', params);
        }

        function validateTag(tag) {
            var param = { thirdPartyAdTag: tag };
            return api.post('api/Creative/validateTag', param);
        }

        function getCreatives(advertiserId, marketerId, page, keyword, filter, sortColumn, sortDescending, singlePage, isAdGroup) {

            return postCall('api/creative/search', page, keyword, filter, sortColumn, sortDescending, singlePage, advertiserId, marketerId, isAdGroup)
        }

        function getCreativeHistory(id) {
            return api.get('api/creative/history/' + id);
        }

        function getCreative(id) {
            return api.get('api/creative/creative/' + id);
        }

        function getCreativeRequest(id) {
            return api.get('api/creative/creativeRequest/' + id);
        }

        function getCreativeRequests(page, keyword, sortColumn, sortDescending) {

            return postCall('api/creative/searchRequests', page, keyword, null, sortColumn, sortDescending)
        }

        function postCall(url, pageNumber, keyword, filter, sortColumn, sortDescending, singlePage, advertiserId, marketerId, isAdGroup) {

            var creativesRequest = {
                advertiserId: advertiserId,
                marketerId: marketerId,
                sortDescending: sortDescending,
                sortColumn: sortColumn,
                pageNumber: pageNumber,
                pageSize: config.pageSize(),
                singlePage: singlePage,
                keyword: keyword,
                filter: filter,
                isAdGroup: isAdGroup
            };

            return api.post(url, creativesRequest);
        }

        function saveCreative(creative) {
            return api.post('api/creative/', creative);
        }

        function saveBulkCreatives(creatives) {
            return api.post('api/creative/bulk', creatives);
        }

        function validateDOAGDates(adGroup) {
            return api.post('api/adgroup/ValidateDOAGDates', adGroup);
        }

        function saveBulkAdGroups(adGroups) {
            return api.post('api/adgroup/bulk', adGroups);
        }

        function resendBulkCreatives(creativeIds) {
            return api.post('api/creative/bulkresend', creativeIds);
        }

        function resendBulkCampaigns(campaignIds) {
            return api.post('api/campaign/bulkresend', campaignIds);
        }

        function refreshThumbnail(creativeId) {
            return api.get('api/creative/refreshthumbnail/' + creativeId);
        }

        function copyCreative(id, advertiserId) {
            if (advertiserId)
                return api.get('api/creative/copy/' + id + '/' + advertiserId);
            else
                return api.get('api/creative/copy/' + id);
        }

        function removeCreative(id) {
            return api.delete('api/creative/' + id);
        }

        function submitRequestCreative(requestCreative) {
            return api.post('api/creative/request', requestCreative);
        }

        function processRequestCreative(requestCreative) {
            return api.post('api/creative/process-request', requestCreative);
        }

        function uploadAdminOnlyFile(path, file, audienceSubType, advertiserId, isSave, campaignId) {
            return Upload.upload({
                url: this.API_SERVER_URL + 'api/' + path, //'api/Creative/ImageUpload',
                method: "POST",
                data: { audienceSubType: audienceSubType, advertiserId: advertiserId, isSave: isSave, campaignId: campaignId },
                file: file
            });
        }

        function uploadFile(path, file) {
            return Upload.upload({
                url: this.API_SERVER_URL + 'api/' + path, //'api/Creative/ImageUpload',
                method: "POST",
                data: { fileUploadObj: { testString1: "Test string 1", testString2: "Test string 2" } },
                file: file
            });
        }

        function uploadData(path, data, type, contentType = "text/csv") {
            var file = new Blob([data], { type: contentType });

            return Upload.upload({
                url: this.API_SERVER_URL + 'api/' + path, //'api/Creative/ImageUpload',
                method: "POST",
                data: {
                    dateTime: formatDate(new Date()), exportType: type
                },
                file: file
            });
        }

        function formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;
            var strTime = hours + '.' + minutes + '.' + seconds + ampm;
            return date.getMonth() + 1 + "." + date.getDate() + "." + date.getFullYear() + "_" + strTime;
        }

        function removeFile(filename) {
            return api.get('api/statistics/remove/?filename=' + filename);
        }

        function downloadFileFromUrl(url, fileName) {
            var param = { url: url, fileName: fileName };
            return api.postDownload('api/creative/download-file-from-url', param);
        }
        function getTotalCpm({segments, brandSafetySegments, crossDeviceExtension, tapAdCrossDeviceExtension, adGroupId, channelID, mediaBid}){
            return $http({
                url: process.env.ACT_API + '/api/activation/GetTotalCpm',
                data: { segments, brandSafetySegments, crossDeviceExtension, tapAdCrossDeviceExtension, adGroupId, channelID, mediaBid },
                method: 'POST',
                params: {},
            }).then(function (data) { console.log(data); return data.data; });
        }

        function downloadFileFromUrlSafari(url, fileName) {
            var obj = { url: url, fileName: fileName };
            return $http({
                url: appSettings.apiUrl + 'api/creative/download-file-from-url',
                data: obj,
                method: 'POST',
                params: {},
                responseType: 'arraybuffer'
            }).then(function (data) {

                var file = new Blob([data.data], {
                    type: 'image/jpg'
                });

                return Upload.upload({
                    url: appSettings.apiUrl + 'api/creative/image-upload-with-name',
                    method: "POST",
                    data: {
                        fileName: fileName
                    },
                    file: file
                });
            });
        }

        function removeImageSafari(filename) {
            return api.get('api/creative/remove-image/?filename=' + filename);
        }

        function getCsvPreview(bucket, filename, rowsSkip, rowsTake) {
            return api.get('api/utils/getCsvPreview/?bucket=' + bucket + '&filename=' + filename + '&rowsskip=' + rowsSkip + '&rowsTake=' + rowsTake);
        }

        function saveInventoryList(blkList) {
            return api.post('api/inventoryList/', blkList);
        }

        function getInventoryLists(keyword, marketerId) {
            return api.get('api/inventoryList/search' + (keyword ? '?keyword=' + keyword : '') + (marketerId ? (keyword ? '&' : '?') + 'marketerId=' + marketerId : ''));
        }

        function getInventoryList(inventoryListId) {
            return api.get('api/inventoryList/' + inventoryListId);
        }

        function removeInventoryList(id) {
            return api.delete('api/inventoryList/' + id);
        }

        function copyInventoryList(id) {
            return api.get('api/inventoryList/copy/' + id);
        }

        function saveKeywordList(blkList) {
            return api.post('api/keywordList/', blkList);
        }

        function getKeywordLists(keyword, advertiserId) {
            return api.get('api/keywordList/search' + (keyword ? '?keyword=' + keyword : '') + (advertiserId ? (keyword ? '&' : '?') + 'advertiserId=' + advertiserId : ''));
        }

        function getKeywordList(keywordListId) {
            return api.get('api/keywordList/' + keywordListId);
        }

        function removeKeywordList(id) {
            return api.delete('api/keywordList/' + id);
        }

        function copyKeywordList(id) {
            return api.get('api/keywordList/copy/' + id);
        }

        function saveBlockingProfile(blockingProfile) {
            return api.post('api/BlockingProfile/', blockingProfile);
        }

        function getBlockingProfiles(keyword, advertiserId) {
            return api.get('api/BlockingProfile/search' + (keyword ? '?keyword=' + keyword : '') + (advertiserId ? (keyword ? '&' : '?') + 'advertiserId=' + advertiserId : ''));
        }

        function getBlockingProfile(blockingProfileId) {
            return api.get('api/BlockingProfile/' + blockingProfileId);
        }

        function removeBlockingProfile(id) {
            return api.delete('api/BlockingProfile/' + id);
        }

        function copyBlockingProfile(id) {
            return api.get('api/BlockingProfile/copy/' + id);
        }

        function getFbPreview(fbAdId, ad_format) {
            return api.get('api/creative/fb-creative-preview?fbAdId=' + fbAdId + '&ad_format=' + ad_format);
        }

        function migrateAdGroups() {
            return api.get('api/adgroup/migrateadgroups');
        }
    }



})();
